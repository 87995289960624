import React from "react";
import NavBar from "components/navigation/NavBar.js";
import {
  HStack,
  VStack,
  Hide,
  Box,
  Text,
  Heading,
  Stack,
  Button,
  Center,
  Show
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Pattern from "assets/images/bg.png";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";

const SearchForm = () => {
  return (
    <Box
      h="200px"
      p={5}
      w={{ base: "100%", md: "100%", lg: "60%" }}
      background="none"
    >
      <Center width="100%">
        <VStack align="stretch" minH="40px">
          <BannerText />
        </VStack>
      </Center>
    </Box>
  );
};

const BannerText = () => {
  return (
    <Box minH="30px" background="none" p="2">
      <Box width="100%">
        <Heading
          color="white"
          textAlign="start"
          as="h1"
          fontSize={{ base: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
          fontFamily="Spantran"
          fontWeight="500"
        >
          Decentralized Sport
          <Text color="secondary.500" as="span">
            {" "}
            Betting{" "}
          </Text>
          with Trustless Protocol
        </Heading>
        <Text
          mt={3}
          mb={1}
          color="white"
          textAlign="start"
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
          fontFamily="ManropeLight"
        >
          Fastest way to organize and place bets on contest like Soccer, NBA, UFC, and other competitive events
        </Text>
        {/*<Text
          mt={3}
          mb={1}
          color="white"
          textAlign="start"
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
          fontFamily="ManropeLight"
        >
          Payment no hard, na you dey use the wrong card
      </Text>*/}
      </Box>
      <Stack spacing={3} direction={"row"} mt="7" align="center">

      <Hide above='md'>
        <Link to="/app/matches">
          <Button
            class="banner-btn"
            size="md"
            rounded={"full"}
            color="brand.500"
            fontFamily="ManropeLight"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            Launch App &nbsp;{" "}
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        </Link>
       </Hide>
        &nbsp;&nbsp;

       <Hide below='md'>
        <a href={"https://everlink.tools/betralix"} target="_blank">
          <Button
            class="secondary-btn"
            size="md"
            rounded={"full"}
            color="secondary.500"
            fontFamily="ManropeLight"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            Join Community &nbsp;{" "}
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        </a>
        </Hide>
        {/*
        <Link to="/auth/login">
          <Button rounded={"full"} px={6}>
            Login
          </Button>
        </Link>*/}
      </Stack>
    </Box>
  );
};

const Banner = () => {
  return (
    <Box
      w="100%"
      background="brand.500"
      backgroundImage={`url(${Pattern})`}
      backgroundPosition="center center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      pb={{ sm: 0, md: 7 }}
    >
      <HStack background="none">
        <NavBar zIndex="10" />
      </HStack>
      <HStack
        minH={{ base: "200px", sm: "250px", md: "400px" }}
        pb={20}
        background="none"
      >
        <SearchForm />
      </HStack>
    </Box>
  );
};

export default Banner;
