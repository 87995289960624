import React, { useState } from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Center,
  Tag,
  TagLabel,
  Flex,
  Button,
  HStack,
  VStack,
  Text,
  Image,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Show,
} from "@chakra-ui/react";
import Team1 from "assets/images/team-logo-1.png";
import Team2 from "assets/images/team-logo-2.png";
import Background from "assets/images/bg4.png";

function HowItWorks() {
  const [step, setStep] = useState(1);
  return (
    <Container
      maxW={"100%"}
      p={10}
      background="brand.500"
      backgroundImage={`url(${Background })`}
      backgroundPosition="center center"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <Box width="100%" textAlign={"center"}>
        <Heading
          fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
          fontFamily="Spantran"
          fontWeight="400"
          lineHeight={"100%"}
          color="white"
        >
          <Text as="span" color="secondary.500">
            Betralix
          </Text>{" "}
          in quick and easy steps?
        </Heading>
      </Box>

      <SimpleGrid width="100%" columns={{ sm: 1, md: 2 }} mt="50">
        <Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <VStack width={{ md: "70%", lg: "100%" }}>
              <Box
                width="100%"
                borderRadius={"10"}
                background="secondary.500"
                padding="10"
                onClick={() => {
                  setStep(1);
                }}
              >
                <Text
                  color="#ffffff"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="Spantran"
                  fontWeight="400"
                >
                  Find a Match
                </Text>
                <Text
                  color="#ffffff"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  We add new games every day, you can select from a long list of
                  game options
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="brand.800"
                padding="7"
                onClick={() => {
                  setStep(2);
                }}
              >
                <Text
                  color="white"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="Spantran"
                  fontWeight="400"
                >
                  Select an Outcome
                </Text>
                <Text
                  color="white"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Select one of the three possible outcomes of a match; win,
                  loss, and draw
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="brand.800"
                padding="7"
                onClick={() => {
                  setStep(3);
                }}
              >
                <Text
                  color="white"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="Spantran"
                  fontWeight="400"
                >
                  Enter Stake Amount
                </Text>
                <Text
                  color="white"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Specify the amount you want to stake on choosen outcome
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="brand.800"
                padding="7"
              >
                <Text
                  color="white"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="Spantran"
                  fontWeight="400"
                  onClick={() => {
                    setStep(4);
                  }}
                >
                  Withdraw Winning
                </Text>
                <Text
                  color="white"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Winnings are settled by smart contract after every match and
                  available for withdraw
                </Text>
              </Box>
            </VStack>
          </Box>
        </Box>
        <Box>
          {step === 1 && <FindMatch />}
          {step === 2 && <Outcome />}
          {step === 3 && <Stake />}
        </Box>
      </SimpleGrid>
    </Container>
  );
}

const FindMatch = () => {
  return (
    <Center mt="10">
      <Box
        width={{ md: "100%", lg: "80%" }}
        borderRadius="15"
        background="brand.800"
        minH="200"
      >
        <VStack width="100%" p="4">
          <Box width="100%" mt="4" mb="4">
            <Heading textAlign="center" color="white"
            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
            fontFamily="Spantran">
              Find a Match
            </Heading>
          </Box>
          <Box
            width="100%"
            p="4"
            minH="200"
            borderRadius="10"
            padding="5"
            borderWidth={1}
            borderColor="secondary.500"
          >
            <VStack
              width="100%"
              minHeight="100"
              borderRadius="10"
              background="brand.500"
            >
              <Box
                width="100%"
                p="2"
                borderBottomWidth={1}
                borderBottomColor={"brand.800"}
              >
                <Flex>
                  <Tag size={"sm"} variant="subtle" colorScheme="cyan">
                    <TagLabel>Soccer</TagLabel>
                  </Tag>{" "}
                  <Text color="white"> &nbsp; Mar 23, 2024,3:45PM EDT</Text>
                </Flex>
              </Box>
              <Box
                width="100%"
                p="2"
                borderBottomWidth={1}
                borderBottomColor={"brand.800"}
              >
                <HStack
                  columns={3}
                  width="100%"
                  borderBottomWidth={1}
                  borderBottomColor={"brand.800"}
                >
                  <Box width="100%">
                    <VStack width="100%">
                      <Box width="100%">
                        <Text
                          color="white"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          Arsenal
                        </Text>
                      </Box>
                      <Box width="100%">
                        <Text color="white" textAlign="center">
                          Home
                        </Text>
                      </Box>
                      <Box width="100%">
                        <Center>
                          <Image
                            src={Team1}
                            width="80%"
                            height="auto"
                            alt="team 1"
                          />
                        </Center>
                        <Text mt="2" color="white" textAlign="center">
                          68 DBX Staked <Text>1.63X</Text>
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                  <Box width="100%">
                    <VStack width="100%">
                      <Box
                        width="100%"
                        alignContent={"center"}
                        alignItems={"center"}
                      >
                        <Center>
                          <Tag size={"md"} variant="outline" colorScheme="blue">
                            <TagLabel>VS</TagLabel>
                          </Tag>
                        </Center>
                      </Box>
                      <Box width="100%">
                        <Text color="white" textAlign="center">
                          Draw
                        </Text>
                      </Box>
                      <Box width="100%">
                        <Text mt="2" color="white" textAlign="center">
                          10 DBX Staked <Text>1.1X</Text>
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                  <Box width="100%">
                    <VStack width="100%">
                      <Box width="100%">
                        <Text
                          color="white"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          Chelsea
                        </Text>
                      </Box>
                      <Box width="100%">
                        <Text color="white" textAlign="center">
                          Away
                        </Text>
                      </Box>
                      <Box width="100%">
                        <Center>
                          <Image
                            src={Team2}
                            width="80%"
                            height="auto"
                            alt="team 1"
                          />
                        </Center>
                        <Text mt="2" color="white" textAlign="center">
                          20 DBX Staked <Text>1.27X</Text>
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                </HStack>
                <HStack columns={3} width="100%" mt="4">
                  <Box width="100%">
                    <Center>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        color="white"
                        variant="outline"
                      >
                        Arsenal
                      </Button>
                    </Center>
                  </Box>
                  <Box width="100%">
                    <Center>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        color="white"
                        variant="outline"
                      >
                        Draw
                      </Button>
                    </Center>
                  </Box>
                  <Box width="100%">
                    <Center>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        color="white"
                        variant="outline"
                      >
                        Chelsea
                      </Button>
                    </Center>
                  </Box>
                </HStack>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

const Outcome = () => {
  return (
    <Center mt="10">
      <Box
        width={{ md: "100%", lg: "80%" }}
        borderRadius="15"
        background="brand.800"
        minH="200"
      >
        <VStack width="100%" p="4">
          <Box width="100%" mt="4" mb="4">
            <Heading textAlign="center" color="white"
            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
            fontFamily="Spantran">
              Select an Outcome
            </Heading>
          </Box>
          <Box
            width="100%"
            p="4"
            minH="200"
            borderRadius="10"
            padding="5"
            borderWidth={1}
            borderColor="secondary.500"
          >
            <HStack
              columns={3}
              width="100%"
              mt="4"
              borderRadius="10"
              p="2"
              background="brand.500"
            >
              <Box width="100%">
                <Center>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                  >
                    Arsenal
                  </Button>
                </Center>
              </Box>
              <Box width="100%">
                <Center>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                  >
                    Draw
                  </Button>
                </Center>
              </Box>
              <Box width="100%">
                <Center>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                  >
                    Chelsea
                  </Button>
                </Center>
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

const Stake = () => {
  return (
    <Center mt="10">
      <Box
        width={{ md: "100%", lg: "80%" }}
        borderRadius="15"
        background="brand.800"
        minH="200"
      >
        <VStack width="100%" p="4">
          <Box width="100%" mt="4" mb="4">
            <Heading textAlign="center" color="white"
            fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
            fontFamily="Spantran">
              Enter Stake
            </Heading>
          </Box>
          <Box
            width="100%"
            p="4"
            minH="200"
            borderRadius="10"
            padding="5"
            borderWidth={1}
            borderColor="secondary.500"
          >
            <VStack
              borderRadius="10"
              width="100%"
              p="2"
              borderWidth={1}
              borderColor="secondary.500"
            >
              <Box
                width="100%"
                borderBottomWidth={1}
                borderBottomColor={"secondary.800"}
                pb="2"
              >
                <Tag size={"md"} variant="subtle" colorScheme="cyan">
                  <TagLabel>Ars Wins</TagLabel>
                </Tag>
              </Box>

              <Box
                width="100%"
                pb="2"
                borderBottomWidth={1}
                borderBottomColor={"secondary.800"}
              >
                <FormControl>
                  <FormLabel color="white">Stake Amount</FormLabel>
                  <Input type="text" width="60%" />

                  <FormHelperText>
                    Enter DBX amount you want to stake
                  </FormHelperText>
                </FormControl>

                <Button
                  mt="2"
                  size="md"
                  colorScheme="white"
                  color="#000000"
                  background="white"
                  variant="solid"
                >
                  Stake
                </Button>
              </Box>

              <Box width="100%" pb="2">
                <Text color="white" textAlign="center">
                  Your Potential Win:{" "}
                  <Text as="span" color="secondary.800" fontWeight="bold">
                    4 DBX
                  </Text>
                </Text>

                <Text color="white" textAlign="center" mt="2">
                  Your potential wins changes until match start
                </Text>

                <Text color="white" textAlign="center" mt="2">
                  Total Pool Prize:{" "}
                  <Text as="span" fontWeight="bold" color="secondary.800">
                    100 DBX
                  </Text>
                </Text>

                <Text color="white" textAlign="center" mt="2">
                  Match Start:{" "}
                  <Text as="span" color="secondary.800">
                    March 21,2021 - 1:00 AM
                  </Text>
                </Text>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

export default HowItWorks;
