import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import AboutSection from "./components/About.js";
import HowItWorks from "./components/HowItWorks.js";
import FAQs from "./components/FAQs.js";
import Header from "components/navigation/Header.js";

export default function About() {
  return (
    <Box minH="100vh">
       <Helmet>
        <title>Betralix</title>
        <meta name="description" content="Decentralized Sport Betting with Trustless Protocol: Fastest way to organize and place bets on contest like Soccer, NBA, UFC, and other competitive events" />
        <meta
          name="keywords"
          content="Create bet, become bet maker, p2p match betting, p2p bettings"
        />
      </Helmet>
      <Header />
      <AboutSection />
      <HowItWorks />
      <FAQs />
    </Box>
  );
}
