import {
  Box,
  chakra,
  Container,
  Image,
  Heading,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaTelegram,
} from "react-icons/fa";
import LogoWhite from "assets/images/logo.png";

const Logo = (props) => {
  return (
    <>
      <Flex>
        <Image
          height="auto"
          width="80px"
          src={LogoWhite}
          alt="Decentralized Sport Betting"
          mt="2"
        />
      </Flex>
    </>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      color="#fff"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight={"500"}
      fontSize={"lg"}
      mb={2}
      color="#fff"
      fontFamily="ManropeSemiBold"
    >
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box
      background="#070b24"
      color={useColorModeValue("gray.700", "gray.200")}
      zIndex={1000}
    >
      <Container as={Stack} maxW={"100%"} py={10}>
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 4 }}
          templateColumns={{ sm: "1fr", md: "2fr 1fr 1fr 1fr" }}
          spacing={8}
          mb={{ sm: 10, md: 0 }}
        >
           <Stack
            align={"flex-start"}
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
             >
            <Box align={"flex-start"}>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              style={{ color: "#fff" }}
            >
              support@betralix.com
            </Text>
            {/*<Text
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            >
              Unit 1411, 14/Floor, Cosco Tower, 183 Queen's Road Central, Sheung
              Wan, Hong Kong
              </Text>*/}

            <Stack direction={"row"} spacing={6}>
              <SocialButton
                label={"Twitter"}
                href={"https://twitter.com/betralixhq"}
              >
                <FaTwitter />
              </SocialButton>
              <SocialButton
                label={"Facebook"}
                href={"https://facebook.com/betralixhq"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton label={"Telegram"} href={"https://t.me/betralixhq"}>
                <FaTelegram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack
            align={"flex-start"}
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
          >
            <ListHeader
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
            >
              Company
            </ListHeader>
            <Link
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              to={"/web/about"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              About us</Text>
            </Link>
            <Link
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              to={"/web/terms"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              Terms of Service</Text>
            </Link>
            <Link
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              to={"/web/privacy"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              Privacy Policy</Text>
            </Link>
            <a
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              href={
                "https://infograph.venngage.com/ps/L842oi7YAzM/dark-information-technology-white-paper"
              }
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              White Paper</Text>
            </a>
          </Stack>
          <Stack
            align={"flex-start"}
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
          >
            <ListHeader
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
            >
              Support
            </ListHeader>
            <a
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              fontFamily="ManropeLight"
              href={"https://betralix.tawk.help/"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              Help Center</Text>
            </a>
            <a
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }} 
              fontFamily="ManropeLight"
              href={"https://medium.com/@betralixhq"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              Blog</Text>
            </a>
            <a
              href={"https://t.me/betralixhq"}
              style={{ color: "#fff", fontFamily: "ManropeLight" }}
            ><Text fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}>
              Telegram Channel</Text>
            </a>
          </Stack>
          <Stack
            align={"flex-start"}
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
          >
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
              color="#fff"
              fontFamily="ManropeLight"
            >
              Betralix is a unique peer-2-peer sports betting platform that
              provides astest way to place bet on biggest matches in NBA,
              Soccer, UFC
            </Text>
            <Text
              mt="5"
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              color="#fff"
              fontFamily="ManropeLight"
            >
              © 2024 Betralix. All rights reserved
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
