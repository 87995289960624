import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Container,
  Center,
  Heading,
  InputGroup,
  InputRightAddon,
  Button,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { useSelector } from "react-redux";
import {
  ContractGetTokens,
  CheckFaucetWithdrawal,
  Withdraw,
} from "services/faucetService";
import Bg1 from "assets/images/bg2.png";

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [amount, setAmount] = useState(200);
  const [loading, setLoading] = useState(false);

  const getFaucetToken = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      let response = await CheckFaucetWithdrawal(token);
      if (response.hasOwnProperty("success") && !response.success) {
        toast({
          title: response.message || "Please check back in 24 hours",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      const walletConnect = { isConnected, walletProvider };
      await ContractGetTokens(walletConnect, 200);
      response = await Withdraw(200, token);
      setLoading(false);
      if (!response) {
        toast({
          title: "Check if you have enough gas fee (BNB)",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title:
            response.message || "We have been sent some Testnet DBX tokens",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        title: "Check if you have enough gas fee (BNB)",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Container
        maxW={"100%"}
        py={12}
        backgroundImage={`url(${Bg1})`}
        backgroundSize="100% auto"
        backgroundRepeat="no-repeat"
      >
        <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">
          <Box width="100%" mt="5">
            <Box width="100%" mt="7" mb="7" textAlign="center">
              <Heading size={{ sm: "sm", md: "lg" }} color="white">
                {" "}
                DBX FAUCET{" "}
              </Heading>
              <Text fontSize="16" color="white">
                Obtain DBX Testnet tokens every 24 hours{" "}
              </Text>
            </Box>
          </Box>

          <Center width="100%">
            <Card width={{ sm: "90%", md: "60%" }} background="brand.500" p="4">
              <Center width="100%">
                <Box width={{ sm: "100%", md: "80%" }}>
                  <InputGroup size="sm" mt="4">
                    <Input
                      placeholder="DBX"
                      value={200}
                      color="#ffffff"
                      placeHolderTextColor="white"
                      height="35"
                      isReadOnly={true}
                    />
                    <InputRightAddon children="DBX" height="35" />
                  </InputGroup>
                </Box>
              </Center>

              <Center width="100%" mt="4">
                <Box width={{ sm: "100%", md: "80%" }} textAlign="center">
                  <Button
                    isLoading={loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      getFaucetToken();
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Center>
            </Card>
          </Center>

          <Center width="100%" mt="5">
            <Card
              width={{ sm: "90%", md: "60%" }}
              background="transparent"
              borderWidth="1px"
              borderColor="white"
              p="4"
            >
              <Center width="100%">
                
                <Box width={{ sm: "100%", md: "80%" }}>
                  <Heading size="sm" color="white">
                    Token Info
                  </Heading>

                  <HStack mt="3">
                    <Box width="25%">
                      <Text fontSize="12" as="span" color="white" fontWeight="bold">
                        Network
                      </Text>
                    </Box>
                    <Box width="75%">
                    <Text fontSize="12" as="span" color="white">
                      BSC Testnet
                    </Text>
                    </Box>
                  </HStack>


                  <HStack mt="3">
                    <Box width="25%">
                    <Text fontSize="12" as="span" color="white" fontWeight="bold">
                     Token Address
                    </Text>
                    </Box>
                    <Box width="75%">
                    <Text fontSize="12" as="span" color="white">
                      0xA872679b5D96E30D924A0AB0952F54D72b26EEf5
                    </Text>
                    </Box>
                  </HStack>

                  <HStack mt="3">
                    <Box width="25%">
                    <Text fontSize="12" as="span" color="white" fontWeight="bold">
                      Symbol
                    </Text>
                    </Box>
                    <Box width="75%">
                    <Text fontSize="12" as="span" color="white">
                      DBX
                    </Text>
                    </Box>
                  </HStack>


                  <HStack mt="3">
                    <Box width="25%">
                    <Text fontSize="12" as="span" color="white" fontWeight="bold">
                      Decimal
                    </Text>
                    </Box>
                    <Box width="75%">
                    <Text fontSize="12" as="span" color="white">
                      18
                    </Text>
                    </Box>
                  </HStack>

                </Box>
              </Center>
            </Card>
          </Center>
        </Box>
      </Container>
    </>
  );
}
