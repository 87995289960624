import React from "react";
// Chakra imports
import { SimpleGrid, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Team from "views/admin/match/components/Team";
import Information from "views/admin/match/components/Information";
import Card from "components/card/Card.js";
const { DateTime } = require("luxon");

export default function Match(props) {
  const { match } = props;
    
  const withdrawable = match.withdrawable?"Yes":"No";

  const formatTime = (timestamp)=>{
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  }
  
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card
      _hover={bgItem}
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'>
      <Flex position='relative' align='center'>
        <Text> Match Details</Text>
      </Flex>

      <Team  teamName={match.team1.name} teamLogo={match.team1.logo} teamCountryName={match.team1.countryName} position="Home" />
      <Team  teamName={match.team2.name} teamLogo={match.team2.logo} teamCountryName={match.team2.countryName} position="Away" />
   
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Match Date'
          value={formatTime(match.matchTimestamp) }
        />
        <Information
          boxShadow={cardShadow}
          title='League'
          value={match.league.name}
        />
        <Information
          boxShadow={cardShadow}
          title='Home Score'
          value={match.team1Scores}
        />
        <Information
          boxShadow={cardShadow}
          title='Away Score'
          value={match.team2Scores}
        />
        <Information
          boxShadow={cardShadow}
          title='Outcome'
          value={match.outcome}
        />
        <Information
          boxShadow={cardShadow}
          title='Withdrawable'
          value={withdrawable}
        />
      </SimpleGrid>

    </Card>
  );
}
