// Chakra imports
import {
  Box,
  SimpleGrid,
  Container,
  Flex,
  VStack,
  Image,
  Center,
  Icon,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Container
      width="100%"
      maxW="100%"
      h={{
        sm: "initial",
        md: "unset",
        lg: "100vh",
        xl: "97vh",
      }}
    >
      <SimpleGrid w="100%" columns={{ base: 1, sm: "1", md: 2, lg: 2 }}>
        <Box w="100%">
          <VStack>
            <Box width="100%" pt="5">
              <NavLink to="/web/index" pt="10">
                <Flex
                  align="center"
                  ps={{ base: "25px", lg: "0px" }}
                  pt={{ lg: "0px", xl: "0px" }}
                  w="fit-content"
                >
                  <Icon
                    as={FaChevronLeft}
                    me="12px"
                    h="13px"
                    w="8px"
                  />
                  <Text ms="0px" fontSize="sm">
                    Back to Home
                  </Text>
                </Flex>
              </NavLink>
            </Box>
            <Box w="100%">{children}</Box>
          </VStack>
        </Box>

        <Box>
          <Box
            display={{ base: "none", md: "block" }}
            h="100%"
            minH="100vh"
            w={{ lg: "50vw", "2xl": "44vw" }}
            position="absolute"
            right="0px"
          >
            <Flex
              background="brand.500"
              justify="center"
              align="end"
              w="100%"
              h="100%"
              bgSize="cover"
              bgPosition="50%"
              position="absolute"
              borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
            >
              <Center width="100%" height="100%">
                <Box boxSize="sm">
                  <Image
                    src={illustrationBackground}
                    alt="Betralix Virtual Card"
                    borderRadius="full"
                  />
                </Box>
              </Center>
            </Flex>
          </Box>
        </Box>
      </SimpleGrid>
      <Footer />
    </Container>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
