import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  SimpleGrid,
  Center,
  Text,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import Match from "./components/Match.js";
import {
  GetMatch,
} from "services/matchService";

export default function Index() {

  const { matchId } = useParams();
  const toast = useToast();
  const [match, setMatch] = useState(null);
  const [loading, setLoading] = useState(false);

  const getMatch = async () => {
    try {
      setLoading(true);
      const response = await GetMatch(matchId);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setMatch(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} mb="20" width="100%">
 

       <Center width="100%">
         <Box width={{sm: "80%", md: "50%"}}>
          {loading? <LoadingMatch />: (match!==null? <Match match={match} />: <Text>Match not found</Text>) }
          </Box>
        </Center>
       
      </Box>
    </>
  );
}

const LoadingMatch = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
