import React, { useEffect } from "react";
import "assets/css/App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import WebLayout from "layouts/web";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import WebAuthLayout from "layouts/webauth";
import routes from "routes.js";
import routesuser from "routesuser.js";
import Home from "views/web/home";
import About from "views/web/about";
import Login from "views/auth";
import Terms from "views/web/terms";
import Aml from "views/web/aml";
import Privacy from "views/web/privacy";
import Matches from "views/app/matches";
import Match from "views/app/match";
import Single from "views/app/matches/single";
import Profile from "views/app/profile";
import Staking from "views/app/staking";
import Faucet from "views/app/faucet";
import Airdrop from "views/app/airdrop";
import Ref from "views/web/ref";

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && !prop.collapse) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/app" && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/admin" && prop.collapse) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.component}
            key={key}
          >
            {getRoutes(prop.items)}
          </Route>
        );
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const WebRoutes = () => {
    return (
      <Routes>
        {/*<Route path="/" element={<Navigate to="/web/index" replace />} />
         <Route path="/ref/:id" element={<Ref />} />*/}
        <Route path="/" element={<Ref />} />
        <Route path="/web/*" element={<WebLayout />}>
          <Route path="index" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="aml" element={<Aml />} />
          <Route path="*" element={<Navigate to="/web/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/auth/*" element={<WebAuthLayout />}>
          <Route path="index" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/auth/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const UserRoutes = () => {
    return (
      <Routes>
        <Route path="/app/*" element={<UserLayout />}>
          {getRoutes(routesuser)}
          <Route path="match/single/:matchId" element={<Single />} />
          <Route path="match/:matchId/:teamId" element={<Match />} />
          <Route path="*" element={<Navigate to="/app/matches" replace />} />
          <Route path="profile" element={<Profile />} />
          <Route path="staking" element={<Staking />} />
          <Route path="faucet" element={<Faucet />} />
          <Route path="profile/:code" element={<Profile />} />
          <Route path="airdrop" element={<Airdrop />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {getRoutes(routes)}
        </Route>
      </Routes>
    );
  };

  return (
    <BrowserRouter>
        <WebRoutes />
        <AuthRoutes />
        <UserRoutes />
    </BrowserRouter>
  );
}

export default App;
