/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, Circle, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("brand.500", "white");
  let activeLink = useColorModeValue("gray.200", "brand.500");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("brand.800", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const activeSubRoute = (subRoutes) => {
    var path = location.pathname.split("/");
    path = path[path.length - 1];
    return subRoutes.includes(path);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
              key={index}>
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } 
      if(
        route.layout === "/admin" ||
        route.layout === "/user" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <NavLink to={route.layout + route.path} key={index}>
            <Box bg={
              (activeRoute(route.path.toLowerCase()) || (route.subRoutes!==undefined && route.subRoutes.length>0 && activeSubRoute(route.subRoutes)))? activeLink : ""
            }
            borderRadius="15px">
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Circle
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'
                      background="brand.500"
                      width="30px"
                      h="30px">
                      {route.icon}
                    </Circle>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                      fontFamily="ManropeLight">
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? "transparent"
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
              ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='transparent' borderRadius='5px' />
                </HStack>
              </Box>
            )}

          
            {route.collapse? <Box ml="50px">{ (createLinks(route.items)) }</Box>: "" }
          
            </Box>
          </NavLink>
        );
      }
      
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
