import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  SimpleGrid,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAttachMoney, MdBarChart } from "react-icons/md";

import Revenue from "views/admin/default/components/Revenue";
import Statistics from "views/admin/default/components/Statistics";
import {
  GetTodayStatistic,
  GetWeeklyRevenue,
  GetWeeklyStatistic,
} from "services/statService";

export default function UserReports() {
  // Chakra Color Mode
  const toast = useToast();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState(null);
  const [categories, setCategories] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const todayMatches = async () => {
    try {
      setLoading(true);
      const response = await GetTodayStatistic(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setStat(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const weeklyRevenue = async () => {
    try {
      setLoading(true);
      const response = await GetWeeklyRevenue(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setCategories(response.data.days);
        setRevenue([
          {
            name: "Stakes",
            data: response.data.revenues,
          },
          {
            name: "Commission",
            data: response.data.commissions,
          },
        ]);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const weeklyStatistic = async () => {
    try {
      setLoading(true);
      const response = await GetWeeklyStatistic(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setStat(response.data);
        setCategories(response.data.days);
        setStatistics([
          {
            name: "Total Stakes",
            data: response.data.totalStakes,
          },
          {
            name: "Total Staked",
            data: response.data.totalStaked,
          },
          {
            name: "Total Matches",
            data: response.data.totalMatches,
          },
        ]);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    todayMatches();
    weeklyRevenue();
    weeklyStatistic();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {stat != null &&
        (() => {
          return (
            <>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap="20px"
                mb="20px"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdBarChart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Today Matches"
                  value={stat.todayMatches}
                />

                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdBarChart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Today Unsettled Matches"
                  value={stat.todayUnsettledMatches}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Today Staked Amount"
                  value={stat.todayStakedAmount}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdBarChart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Today Total Stakers"
                  value={stat.todayTotalStakers}
                />
              </SimpleGrid>
            </>
          );
        })()}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {revenue != null && (
          <Revenue categories={categories} revenue={revenue} />
        )}
        {statistics != null && (
          <Statistics categories={categories} statistics={statistics} />
        )}
      </SimpleGrid>
    </Box>
  );
}
