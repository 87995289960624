import {
  Skeleton,
  Stack,
  SkeletonText,
  Box,
  Grid,
  useToast,
} from "@chakra-ui/react";
import FixturesTable from "views/admin/match/components/FixturesTable";
import DateFilter from "components/filter/DateFilter";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetFixtures } from "services/matchService";
const { DateTime } = require("luxon");

export default function Fixtures() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [date, setDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"));
  const [columnsData, setColumnsData] = useState([
    {
      Header: "FIXTURE ID",
      accessor: "fixtureid",
    },
    {
      Header: "HOME",
      accessor: "home",
    },
    {
      Header: "AWAY",
      accessor: "away",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ]);

  const toast = useToast();

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const response = await GetFixtures(date, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        const allfixtures = [];
        response.data.map((fixture) => {
          allfixtures.push({
            fixtureid: fixture.fixture.id,
            home: fixture.teams.home.name,
            away: fixture.teams.away.name,
            date: formatTime(fixture.fixture.timestamp),
          });
        });
        setTableData(allfixtures);
        setTotalRecord(allfixtures.length);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    initFilterSubmit();
  }, []);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <Box w="100%" p="10px">
        <DateFilter
          date={date}
          setDate={setDate}
          initFilterSubmit={initFilterSubmit}
          loading={loading}
        />

        {loading ? (
          <LoadingMatches />
        ) : tableData.length > 0 ? (
          <FixturesTable
            columnsData={columnsData}
            tableData={tableData}
            totalRecord={totalRecord}
          />
        ) : (
          "No record found"
        )}
      </Box>
    </Box>
  );
}

const LoadingMatches = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
