import React from "react";
import { Icon } from "@chakra-ui/react";
import { BiSearchAlt, BiDiamond } from "react-icons/bi";
import { FiSearch, FiUser } from "react-icons/fi";
import { FaRegUser, FaHistory } from "react-icons/fa";
import { IoIosFootball } from "react-icons/io";

import Matches from "views/app/matches";
import History from "views/app/history";
import Profile from "views/app/profile";
import Staking from "views/app/staking";

const routes = [
  {
    name: "Matches",
    layout: "/app",
    path: "matches",
    icon: <Icon as={IoIosFootball} width="20px" height="20px" color="white" />,
    component: <Matches />,
    collapse:false
  },
  {
    name: "History",
    layout: "/app",
    path: "history",
    icon: <Icon as={FaHistory} width="20px" height="20px" color="white" />,
    component: <History />,
    collapse:false
  },
  {
    name: "Profile",
    layout: "/app",
    path: "profile",
    icon: <Icon as={FaRegUser} width="20px" height="20px" color="white" />,
    component: <Profile />,
    collapse:false
  },
  {
    name: "Staking",
    layout: "/app",
    path: "staking",
    icon: <Icon as={BiDiamond} width="20px" height="20px" color="white" />,
    component: <Staking />,
    collapse:false
  },
  ];

export default routes;

