import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export default function App() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const referral = new URLSearchParams(search).get("ref");
  localStorage.setItem("referral", referral);

  useEffect(() => {
    if(referral!==null && referral!==""){
      navigate("/app/airdrop");
    }else{
      navigate("/web/home");
    }
  });

  return <></>;
}
