import React from "react";
import {Box,
        VStack,
        Heading,
        Text,
        List,
        ListIcon,
        ListItem,
        OrderedList} from "@chakra-ui/react";
import {MdCheckCircle} from "react-icons/md";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Privacy() {
  return (
    <Box minH='100vh'>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Read our privacy policy to learn how we handle your digital safety, and how we protect your privacy and security. We are committed to keeping your information safe and secure, and to being transparent about our privacy practices." />
        <meta
          name="keywords"
          content="Create bet, become bet maker, p2p match betting, p2p bettings"
        />
      </Helmet>
       <Header />

       <VStack spacing="2"  color="white" background="brand.500">
        <Box> <Heading as="h3" mt="10"> Privacy Policy</Heading>  </Box>
        <Box p={{base:'4',sm:'10', md:'20'}}> 
          <Box>
            <Text fontSize={{base:'md', sm:'lg', md:'xl'}}> 
              <b>Last updated: 2024-02-01</b><br/>
              Tradersfield Ltd ("Tradersfield," "we," "us," or "our") operates Betralix.com (the "Platform"). This Privacy Policy outlines our policies regarding the collection, use, and disclosure of personal information when you use our Platform.
            </Text>
          </Box>
          <OrderedList spacing="4" mt="5">
            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Information Collection and Use</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Personal Information: </Text> When you use our Platform, we may collect certain personally identifiable information, including but not limited to your name, email address, contact information, and cryptocurrency wallet address. We collect this information for the purpose of providing and improving our services, verifying your identity, and communicating with you.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Usage Data: </Text> We may also collect information about your use of the Platform, including your IP address, browser type, operating system, referral URLs, and other similar data. This information may be used for analytics purposes to improve the functionality and user experience of the Platform.</Text>
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Cookies and Tracking Technologies</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Cookies: </Text> We may use cookies and similar tracking technologies to track the activity on our Platform and store certain information. Cookies are files with small amounts of data which may include an anonymous unique identifier. You have the option to accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our Platform.</Text>
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Data Security</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Security Measures: </Text> We take reasonable precautions to protect the security of your personal information collected through the Platform. However, please be aware that no method of transmission over the internet or method of electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Data Sharing and Disclosure</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Third-Party Service Providers: </Text> We may employ third-party companies and individuals to facilitate our Platform, provide the Platform on our behalf, perform Platform-related services, or assist us in analyzing how our Platform is used. These third parties may have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Legal Compliance: </Text> We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Data Retention</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Retention Period: </Text> We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</Text>
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Your Rights</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Access, Correction, and Deletion: </Text> You have the right to access, correct, or delete your personal information stored on our Platform. You may request access to your personal information by contacting us using the contact information provided below. </Text>
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Changes to this Privacy Policy</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Notification of Changes: </Text> We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page with an updated revision date. You are advised to review this Privacy Policy periodically for any changes. Your continued use of the Platform after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. </Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Contact Us</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"> If you have any questions or concerns about this Privacy Policy, please contact us at info@betralix.com </Text>
            </ListItem>


         </OrderedList>  
        </Box>

       </VStack>
       
    </Box>
  );
}