import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Container,
  Heading,
  Grid,
  GridItem,
  SimpleGrid,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import Background from "assets/images/bg3.png";

function FAQs() {
  return (
    <Container
      maxW={"100%"}
      p={10}
      background="brand.500"
      backgroundImage={`url(${Background })`}
      backgroundPosition="center center"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <div
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      background="rgba(0, 0, 0, 0.2)"
      zIndex="-1"
    > </div>
      <SimpleGrid width="100%" columns={{ sm: 1, md: 2 }} mt="10">
        <Box textAlign={"center"}>
          <Heading
            as="h3"
            fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
            fontFamily="Spantran"
            fontWeight="400"
            textAlign={"center"}
            color="white"
          >
            Frequently Asked <Text color="secondary.500">Questions ?</Text>
          </Heading>
        </Box>
        <Box>
          <Accordion allowMultiple>
            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box flex="1" textAlign="left" ml="2" mt="3" mb="0.7rem">
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="#ffffff"
                        >
                          {" "}
                          What is Betralix?{" "}
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                     fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                     fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      Betralix is a unique peer-2-peer sports betting platform.
                      Unlike traditional sports betting platforms, there is no
                      middleman involved, Betralix platform relies on blockchain
                      and is entirely powered by smart contract.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="#ffffff"
                        >
                          {" "}
                          Are Betralix Smart Contract audited
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      We take Security very seriously, we carry out smart
                      contract audit, site security test and other wide variety
                      of test regularly to ensure that investors funds are fully
                      safe and secured. More so all our audit will be opened to
                      the general public and can be verifiable by anyone.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="#ffffff"
                        >
                          {" "}
                          When do my betting have a commission tax applied
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      Betralix have a 10% commission tax applied to your
                      dividend when you win a bet. Sounds like a lot of tax
                      right?, the only notable exception is that your bet
                      cancellation are not taxed.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="white"
                        >
                          Who are my betting with
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      fontFamily="ManropeLight"
                      color="white"
                    >
                      You are betting against bettors who have stacked on other
                      outcomes for a specific match called liquidity providers.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="#ffffff"
                        >
                          How are betting odds determined?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      The betting odds are determined from the total liquidity
                      provided by bettors on other outcome for a particular
                      match. We eliminate centralized market makers, odds are
                      usually higher than what is obtainable in the market per
                      match, in most cases up to 2X.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon
                          as={AiOutlineMinus}
                          boxSize={6}
                          color="secondary.500"
                        />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} color="white" />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                          fontFamily="ManropeLight"
                          color="#ffffff"
                        >
                          How do i get paid?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      fontFamily="ManropeLight"
                      color="#ffffff"
                    >
                      Your dividend in BNB is withdrawable from the smart
                      contract back to your wallet after the outcome of a match
                      has been determined.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </SimpleGrid>
    </Container>
  );
}

export default FAQs;
