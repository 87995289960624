import {  
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText 
} from "@chakra-ui/react";
import Card from "components/card/Card";

export default function TextFilter(props) {
  const { searchText, setSearchText, searchTextLabel, initFilterSubmit } = props;
  return (
    <Card
    w='100%'
    h='200px'
    p='20px'
    mb='20px'
    >
       <form>
        <FormControl>
          <FormLabel htmlFor='date' fontWeight="bold">Filter By {searchTextLabel} </FormLabel>
          <Input id='date' type='text' defaultValue={searchText} onChange={(e)=>setSearchText(e.target.value)} />
          <FormHelperText>Enter {searchTextLabel}.</FormHelperText>
        </FormControl>
        <Button
            me='100%'
            mb='50px'
            w='140px'
            minW='140px'
            mt={{ base: "20px", "2xl": "auto" }}
            variant='brand'
            fontWeight='500' 
            onClick={(e)=>{ e.preventDefault(); initFilterSubmit(); }} >
            Find
          </Button>
      </form>
    </Card>
  );
}