import React from "react";
// Chakra imports
import {
  Box,
  Center,
  Button,
  Text,
  Image,
  Icon,
  VStack,
  TagLabel,
  HStack,
  Spinner,
  Tag,
  Flex,
} from "@chakra-ui/react";
import { FiCheckSquare, FiX } from "react-icons/fi";
// Custom component
const { DateTime } = require("luxon");

export default function Match(props) {
  const { match, setStakeId, stakeId, handleWithdrawReward, onOpen, onRemoveStakeOpen, withdrawLoading } =
    props;

  const calculateOdds = (teamStakes, totalStakes) => {
    let teamPercentage = (teamStakes * 100) / totalStakes;
    teamPercentage = 100 - teamPercentage;
    teamPercentage = teamPercentage / 100;
    let teamOdds = teamPercentage * totalStakes;
    teamOdds = teamOdds / teamStakes;
    teamOdds = teamOdds === 1 ? (2) : teamOdds;
    teamOdds = teamOdds < 1 ? (1 + teamOdds) : teamOdds;
    teamOdds = !isFinite(teamOdds) ? 1 : teamOdds;
    teamOdds = teamOdds % 1 === 0 ? parseInt(teamOdds) : teamOdds;
    return teamOdds.toFixed(2);
  };

  let won = false;
  let dividend;
  let teamStakes;

  if (match.game !== null && match.selectedTeam._id === match.game.team1._id && match.odds !== "draw") {
    dividend =
      match.game.team2Stakes +
      match.game.drawStakes -
      (match.game.team2Stakes + match.game.drawStakes) *
      process.env.REACT_APP_TOTAL_COMMISSION;
    teamStakes = match.game.team1Stakes;
  } else if (
    match.game !== null &&
    match.selectedTeam._id === match.game.team2._id && match.odds !== "draw"
  ) {
    dividend =
      match.game.team1Stakes +
      match.game.drawStakes -
      (match.game.team1Stakes + match.game.drawStakes) *
      process.env.REACT_APP_TOTAL_COMMISSION;
    teamStakes = match.game.team2Stakes;
  } else if (match.game !== null && match.odds === "draw") {
    dividend =
      match.game.team2Stakes +
      match.game.team1Stakes -
      (match.game.team2Stakes + match.game.team1Stakes) *
      process.env.REACT_APP_TOTAL_COMMISSION;
    teamStakes = match.game.drawStakes;
  } else {
    dividend = 0;
    teamStakes = 0;
  }
  if (dividend === 0) {
    dividend = 0.1;
  }

  if (
    match.game !== null &&
    match.game.settledOffchain &&
    !match.game.draw &&
    match.selectedTeam?._id === match.game.team1?._id &&
    match.game.winner?._id === match.selectedTeam?._id
  ) {
    won = true;
  } else if (
    match.game !== null &&
    match.game.settledOffchain &&
    !match.game.draw &&
    match.selectedTeam?._id === match.game.team2?._id &&
    match.game.winner?._id === match.selectedTeam?._id
  ) {
    won = true;
  } else if (
    match.game !== null &&
    match.game.settledOffchain &&
    match.odds === "draw" &&
    match.game.draw === true
  ) {
    won = true;
  } else {
    won = false;
  }

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  };

  const curTime = DateTime.local();
  const currentTime = curTime.toSeconds();

  return (
    <>
      <VStack
        width="100%"
        minHeight="100"
        borderRadius="10"
        background="brand.500"
      >
        <Box
          width="100%"
          p="2"
          borderTopRadius="10"
          borderBottomWidth={1}
          borderBottomColor={"brand.800"}
          background="secondary.300"
        >
          <HStack width="100%" columns="2">
            <Box width="50%">
              <Flex>
                <Tag size={"sm"} variant="subtle" colorScheme="cyan">
                  <TagLabel fontSize={{sm:"10px", md:"12px"}}>Soccer</TagLabel>
                </Tag>{" "}
                <Text color="white" fontSize={{sm:"sm", md:"md"}} ml="2">
                  {match.selectedTeam._id === match.game?.team1?._id && match.odds !== "draw"
                    ? match.game.team1.name
                    : ""}
                  {match.selectedTeam._id === match.game?.team2?._id && match.odds !== "draw"
                    ? match.game?.team2?.name
                    : ""}
                  {match.selectedTeam._id === match.game?.team1?._id && match.odds === "draw" ? "Draw" : ""}
                </Text>
                <Text>
                  {match.game.settledOffchain && won && (
                    <Icon as={FiCheckSquare} w={5} h={5} ml="2" color="white" />
                  )}
                  {match.game.settledOffchain && !won && (
                    <Icon as={FiX} w={5} h={5} ml="2" color="white" />
                  )}
                </Text>
              </Flex>
            </Box>
            <Box width="50%" textAlign="right">
              <Text color="white" fontSize={{sm:"sm", md:"md"}}>
                {" "}
                &nbsp;{formatTime(match.game.matchTimestamp)}
              </Text>
            </Box>
          </HStack>
        </Box>


        <Box
          width="100%"
          p="2"
          borderBottomWidth={1}
          borderBottomColor={"brand.800"}
        >
          <HStack columns={3} width="100%" minHeight="300">
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%">
                  <Text color="white" fontWeight="bold" textAlign="center" fontSize={{sm:"md", md:"lg"}}>
                    {match.game.team1.name}
                  </Text>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Home
                  </Text>
                </Box>
                <Box width="100%">
                  <Center>
                    <Image
                      src={match.game.team1.logo}
                      width="80%"
                      height="auto"
                      alt="team 1"
                    />
                  </Center>
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.game.team1Stakes)}{" "}
                    DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.game.team1Stakes, (match.game.team1Stakes + match.game.team2Stakes + match.game.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%" alignContent={"center"} alignItems={"center"}>
                  <Center>
                    <Tag size={"md"} variant="outline" colorScheme="blue">
                      <TagLabel>VS</TagLabel>
                    </Tag>
                  </Center>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Draw
                  </Text>
                </Box>
                <Box width="100%">
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.game.drawStakes)}{" "}
                    DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.game.drawStakes, (match.game.team1Stakes + match.game.team2Stakes + match.game.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%">
                  <Text color="white" fontWeight="bold" textAlign="center" fontSize={{sm:"md", md:"lg"}}>
                    {match.game.team2.name}
                  </Text>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Away
                  </Text>
                </Box>
                <Box width="100%">
                  <Center>
                    <Image
                      src={match.game.team2.logo}
                      width="80%"
                      height="auto"
                      alt="team 1"
                    />
                  </Center>
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.game.team2Stakes)}{" "}
                    DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.game.team2Stakes, (match.game.team1Stakes + match.game.team2Stakes + match.game.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
          </HStack>

          <HStack
            columns={2}
            width="100%"
            mt="4"
            pt="2"
            borderTopWidth={1}
            borderTopColor="secondary.300"
          >
            <Box width="50%">
              {(() => {
                let dividendPercentage = match.stake * 100;
                dividendPercentage = dividendPercentage / teamStakes;
                const div = (dividendPercentage / 100) * Number(dividend);
                //const withdrawable = (div + match.stake).toFixed(2);
                return (
                  <>
                    <Text color="white" fontSize={{sm:"sm", md:"md"}}>
                      {match.game.settledOffchain && !match.game.undecided && !match.game.draw && (match.game.team1Scores !== null && match.game.team2Scores !== null)
                        ? match.game.winner?.name + " Won"
                        : ""}
                      {match.game.settledOffchain && !match.game.undecided && match.game.draw && (match.game.team1Scores !== null && match.game.team2Scores !== null)
                        ? "Match Ended Draw"
                        : ""}

                      {match.game.settledOffchain && match.game.undecided && "Match Undecided"}
                    </Text>

                    {
                      match.stakedOnBlockchain && <Text color="white" fontSize={{sm:"sm", md:"md"}}>
                        {match.game.settledOffchain && match.game.withdrawable
                          ? "Stake Reward"
                          : "Stake Reward"}{" "}
                        : {div.toFixed(2)} DBX
                      </Text>
                    }
                    {
                      match.stakedOnBlockchain &&
                      <Text color="white" fontSize={{sm:"sm", md:"md"}}>
                        {" "}
                        Your Stake: {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(match.stake)} DBX
                      </Text>
                    }
                  </>
                );
              })()}
            </Box>
            <Box width="50%" textAlign="right">

              {!match.stakedOnBlockchain && <Spinner color="white" />}
              {match.stakedOnBlockchain && !match.game.undecided && Number(currentTime) < match.game.matchTimestamp && !match.game.settledOffchain && !match.game.withdrawable ? (
                <Button
                  size="sm"
                  colorScheme="cyan"
                  variant="outline"
                  textAlign="right"
                  fontSize={{sm:"sm", md:"md"}}
                  onClick={(e) => {
                    setStakeId(match._id);
                    onOpen();
                  }}
                >
                  Add Stake
                </Button>
              ) : (
                ""
              )}

              {match.stakedOnBlockchain && Number(currentTime) < match.game.matchTimestamp && match.stake > 0 &&
                !match.game.settledOffchain && !match.game.withdrawable ? (
                <Button
                  mt="1"
                  size="sm"
                  colorScheme="cyan"
                  variant="outline"
                  fontSize={{sm:"sm", md:"md"}}
                  onClick={(e) => {
                    setStakeId(match._id);
                    onRemoveStakeOpen();
                  }}
                >
                  Remove Stake
                </Button>
              ) : (
                ""
              )}

              {match.stakedOnBlockchain && match.game.settledOffchain &&
                match.game.withdrawable &&
                !match.fundsWithdrawed &&
                won ? (
                <Button
                  mt="1"
                  size="sm"
                  colorScheme="cyan"
                  variant="outline"
                  fontSize={{sm:"sm", md:"md"}}
                  isLoading={withdrawLoading && match._id === stakeId}
                  loadingText="Wait.."
                  onClick={(e) => {
                    setStakeId(match._id);
                    handleWithdrawReward(match._id);
                  }}
                >
                  Withdraw
                </Button>
              ) : (
                ""
              )}


              {match.stakedOnBlockchain && match.game.settledOffchain &&
                match.game.withdrawable &&
                match.game.undecided &&
                !match.fundsWithdrawed ? (
                <Button
                  mt="1"
                  size="sm"
                  colorScheme="cyan"
                  variant="outline"
                  fontSize={{sm:"sm", md:"md"}}
                  isLoading={withdrawLoading}
                  loadingText="Wait.."
                  onClick={(e) => {
                    setStakeId(match._id);
                    handleWithdrawReward(match._id);
                  }}
                >
                  Refund
                </Button>
              ) : (
                ""
              )}


              {
                match.stakedOnBlockchain && match.fundsWithdrawed && (
                  <Button
                    mt="1"
                    size="sm"
                    fontSize={{sm:"sm", md:"md"}}
                    colorScheme="whiteAlpha"
                    variant="outline"
                  >
                    Paid
                  </Button>
                )}
            </Box>
          </HStack>
        </Box>
      </VStack>
    </>
  );
}
