import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Select,
  InputGroup,
  InputLeftElement,
  Button,
  SimpleGrid,
  FormLabel,
  Input,
  VStack,
  Stack,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import { FaCalendar } from "react-icons/fa"; 
import { IoIosFootball } from "react-icons/io";
import Match from "./components/Match.js";
import Paginate from "components/navigation/Paginate.js";
import { GetLeagues, GetMatches, SearchMatches } from "services/matchService";

export default function Index() {

  const toast = useToast();
  const [matches, setMatches] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [league, setLeague] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [leaguesLoading, setLeaguesLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);

  const getMatches = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        stakeable: true,
        settledOffchain: false,
        withdrawable: false,
      };
      const response = await GetMatches(filter, pagination);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setPageCount(response.data.count / limit);
        setMatches((prevMatches) => [...prevMatches, ...response.data.matches]);
        if (response.data?.matches.length === 0) {
          setFinished(true);
        }
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getLeagues = async () => {
    try {
      setLeaguesLoading(true);
      const response = await GetLeagues();
      setLeaguesLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setLeagues(response.data);
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLeaguesLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const searchData = {
        league: league,
        searchTerm: searchTerm,
        date: date,
      };
      const response = await SearchMatches(searchData);
      setLoading(false);

      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setMatches(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setMatches([]);
    getMatches();
    getLeagues();

  }, []);
  

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">

      <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "lg", lg: "2xl" }}>
            Fixtures
          </Heading>
        </Box>

        <VStack
          width="100%"
          background="brand.500"
          p="4"
          pb="10"
          spacing="5"
          borderRadius="20"
        >
          <SimpleGrid
            width="100%"
            columns={{ sm: "1", md: "4" }}
            spacing="2"
            pl="5"
            pr="5"
          >
            <Box>
              <FormLabel color="white" fontWeight={"bold"} fontSize={{sm:"sm", md:"md"}}>
                League
              </FormLabel>
              <Select
                placeholder="Select League"
                size="md"
                fontSize={{sm:"sm", md:"md"}}
                color="#ffffff"
                borderWidth="1"
                borderColor="#ffffff"
                onChange={(e) => {
                  setLeague(e.target.value);
                }}
              >
                {leagues &&
                  leagues.map((league) => (
                    <>
                      <option value={league._id}>{league.name}</option>
                    </>
                  ))}
              </Select>
            </Box>
            <Box>
              <FormLabel color="white" fontWeight={"bold"} fontSize={{sm:"sm", md:"md"}}>
                Team Name
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <IoIosFootball color="white" />
                </InputLeftElement>
                <Input
                  fontSize={{sm:"sm", md:"md"}}
                  placeHolder="Search By Team Name"
                  color="white"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Box>
              <FormLabel color="white" fontWeight={"bold"} fontSize={{sm:"sm", md:"md"}}>
                Match Date
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaCalendar color="white" />
                </InputLeftElement>
                <Input
                 fontSize={{sm:"sm", md:"md"}}
                  type="date"
                  placeHolder="Search By Team Name"
                  placeHolderTextColor="white"
                  color="white"
                  onChange={(e) => setDate(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Box>
              <FormLabel color="white">&nbsp;</FormLabel>
              <Button
                isLoading={loading}
                fontSize={{sm:"sm", md:"md"}}
                loadingText="Please Wait"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </SimpleGrid>
        </VStack>

        {loading ? (
          <LoadingMatches />
        ) : (
          <SimpleGrid
            width="100%"
            mt="10"
            mb="10"
            columns={{ sm: "1", md: "2", lg: "3" }}
            spacing="2"
          >
            {matches.length > 0 &&
              matches.map((match, id) => <Match key={id} match={match} />)}
          </SimpleGrid>
        )}

        {matches.length > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getMatches}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

const LoadingMatches = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
