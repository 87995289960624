import axiosInstance from './axiosInstance'; // Adjust the path accordingly
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetTodayPoints = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/point/todaypoints', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const GetTodayStatistic = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/stat/todaystats', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const GetWeeklyRevenue = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/stat/weeklyRevenue', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const GetWeeklyStatistic = async token => {
  try {
    const response = await axiosInstance.get(SERVER_URL + '/stat/weeklyStatistics', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export {
  GetTodayStatistic,
  GetWeeklyStatistic,
  GetWeeklyRevenue,
  GetTodayPoints
};
