import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Heading,
  InputGroup,
  InputRightAddon,
  Button,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Center,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import Match from "./components/Match.js";
import Paginate from "components/navigation/Paginate.js";
import {
  GetMatchHistory,
  GetStake,
  ContractWithrawReward,
  ContractAddStake,
  ContractRemoveStake,
} from "services/matchService";

export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const userId = userState.hasOwnProperty("userId") ? userState.userId : null;
  
  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRemoveStakeOpen,
    onOpen: onRemoveStakeOpen,
    onClose: onRemoveStakeClose,
  } = useDisclosure();

  const [matches, setMatches] = useState([]);
  const [stakeId, setStakeId] = useState("");
  const [amount, setAmount] = useState();
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [addStakeLoading, setAddStakeLoading] = useState(false);
  const [removeStakeLoading, setRemoveStakeLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);

  const getRecords = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const response = await GetMatchHistory(pagination, filter, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setMatches(response?.data?.stakes);
        if (response.data?.stakes.length === 0) {
          setFinished(true);
        }
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  const handleWithdrawReward = async (stakeId) => {
    try {
      const walletConnect = { isConnected, walletProvider };
      setWithdrawLoading(true);
      let response = await GetStake(stakeId, token);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        const stake = response.data;
        if (stake.fundsWithdrawed) {
          toast({
            title: "Stake has been withdrawned already",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        //initBet.emit("initwithdrawrewardroom", stakeId);
        response = await ContractWithrawReward(walletConnect, stakeId);
        if(response.success){
          toast({
            title: "Withdrawal successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          getRecords();
          setWithdrawLoading(false);
        }
      }
    } catch (error) {
      setWithdrawLoading(false);
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleAddStake = async () => {
    try {
      setAddStakeLoading(true);
      //initBet.emit("initaddstakeroom", stakeId);
      const walletConnect = { isConnected, walletProvider };
      const response = await ContractAddStake(walletConnect, stakeId, amount);
      if(response.success){
        toast({
          title: "Stake has been added successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
        setAddStakeLoading(false);
        setLoading(false);
        getRecords();
      }
    } catch (error) {
      setAddStakeLoading(false);
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleRemoveStake = async () => {
    try {
      setRemoveStakeLoading(true);
      //initBet.emit("initremovestakeroom", userId);
      const walletConnect = { isConnected, walletProvider };
      const response = await ContractRemoveStake(walletConnect, stakeId);
      if(response.success){
        toast({
          title: "Stake has been removed successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        //close modal
        onRemoveStakeClose();
        setRemoveStakeLoading(false);
        getRecords();
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

 /* useEffect(() => {
    initBet.on("withdrawreward", () => {
      toast({
        title: "Withdrawal successful",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      getRecords();
      setLoading(false);
    });

    initBet.on("stakeadded", () => {
      toast({
        title: "Stake has been added successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onClose();
      setAddStakeLoading(false);
      setLoading(false);
      getRecords();
    });

    initBet.on("stakeremoved", () => {
      toast({
        title: "Stake has been removed successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      //close modal
      onRemoveStakeClose();
      setRemoveStakeLoading(false);
      getRecords();
    });
  }, []);
  */

  useEffect(() => {
    if (isConnected) {
      const handleOpen = () => {
        console.log('WebSocket connection established');
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'MATCH_STAKED_SUCCESS') {
          if (token !== null) { getRecords(); }
          toast({
            title: "Match staking successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }

        if (message.type === 'MATCH_TOPUP_SUCCESS') {
          if (token !== null) { getRecords(); }
          toast({
            title: "Stake added successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
      };

      const handleReOpen = () => {
        setTimeout(() => {
          handleOpen(); // Attempt to reconnect
        }, 5000); // Reconnect after 5 seconds
      };

      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
      socket.onclose = handleReOpen;
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
        socket.onclose = null;
      };

    }
  }, [isConnected, token, address, getRecords, toast]);


  useEffect(() => {
    if (token !== null) {
     getRecords();
    }
    else{
      setPageCount(0);
      setMatches([]);
    }
  }, [token, from, filter]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background={"#001347"}>
          <ModalHeader>
            <Heading
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
              color="#ffffff"
            >
              Add Stake
            </Heading>
          </ModalHeader>
          <ModalCloseButton color="#ffffff" />
          <ModalBody>
            <Box width="100%" mb={{ base: "0px", "2xl": "20px" }}>
              <Stack spacing={4} w={"full"} maxW={"md"} p={3}>
                <FormControl>
                  <FormLabel color={"white"} fontWeight="bold">
                    Amount
                  </FormLabel>
                  <InputGroup size="sm">
                    <Input
                      placeholder="DBX"
                      color="#ffffff"
                      height="35"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                    <InputRightAddon children="DBX" height="35" />
                  </InputGroup>
                </FormControl>

                <Button
                  mt="2"
                  isLoading={addStakeLoading}
                  loadingText="Please Wait"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddStake();
                  }}
                >
                  Stake
                </Button>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRemoveStakeOpen} onClose={onRemoveStakeClose}>
        <ModalOverlay />
        <ModalContent background={"#001347"}>
          <ModalHeader>
            {" "}
            <Heading
              as="h3"
              size="md"
              color="white"
              fontFamily="ManropeSemiBold"
            >
              Remove Match Stake
            </Heading>
          </ModalHeader>
          <ModalCloseButton color="#ffffff" />
          <ModalBody>
            <Box width="100%" mb={{ base: "10px", "2xl": "20px" }}>
              <Box width="100%">
                <Heading as="h3" size="md" color="white">
                  Are you sure you want to remove your stake from this match?
                </Heading>
              </Box>
              <Box width="100%" textAlign="center">
                <Button
                  mt="5"
                  isLoading={removeStakeLoading}
                  loadingText="Please Wait"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveStake();
                  }}
                >
                  Proceed
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">

        <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "lg", lg: "2xl" }}>
            Bet History
          </Heading>
        </Box>

        <Box width="100%">
          <Stack direction="row" spacing={4}>
            <Button
              onClick={() => {
                setFilter("all");
              }}
              colorScheme="teal"
              variant={filter === "all" ? "solid" : "outline"}
              size="sm"
            >
              All
            </Button>
            <Button
              onClick={() => {
                setFilter("open");
              }}
              colorScheme="teal"
              variant={filter === "open" ? "solid" : "outline"}
              size="sm"
            >
              Open
            </Button>
            <Button
              onClick={() => {
                setFilter("finished");
              }}
              colorScheme="teal"
              variant={filter === "finished" ? "solid" : "outline"}
              size="sm"
            >
              Finished
            </Button>
          </Stack>
        </Box>

        {loading ? (
          <LoadingMatches />
        ) : (
          <SimpleGrid
            width="100%"
            mt="10"
            mb="10"
            columns={{ sm: "1", md: "2", lg: "3" }}
            spacing="2"
          >
            {matches.length > 0 &&
              matches.map((match, id) => (
                <Match
                  key={id}
                  match={match}
                  setStakeId={setStakeId}
                  stakeId={stakeId}
                  handleWithdrawReward={handleWithdrawReward}
                  onOpen={onOpen}
                  onRemoveStakeOpen={onRemoveStakeOpen}
                  getRecords={getRecords}
                  setFilter={setFilter}
                  withdrawLoading={withdrawLoading}
                />
              ))}
          </SimpleGrid>
        )}

        {matches.length > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getRecords}
            />
          </Box>
        )}

        {(!isConnected || !token) && <Center width="100%" height="300">
            <Text fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }} color="#ffffff">Please Connect Wallet</Text>
          </Center>
        }

      </Box>
    </>
  );
}

const LoadingMatches = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
