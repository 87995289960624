import { Box, Grid, useToast} from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import TextFilter from "components/filter/TextFilter";
import React, {useEffect,useState} from "react";
import axios from "axios";
export default function Users() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading,setLoading] = useState(false);
  const [users,setUsers] = useState([]);
  const [searchText,setSearchText] = useState('');
  const token = localStorage.getItem("authToken");
  const toast = useToast();
  
  const initFilterSubmit = async()=>{
    try{
      setLoading(true);
      const {data:response} = await axios.post(SERVER_URL+'/user/search',{searchText},{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      });
      if(response.error){
        toast({
          title: response.error || "An error occured",
          status: 'error',
          duration: 9000,
          position:'top-right',
          isClosable: true,
        })
      }
      if(!response.success){
        toast({
          title: response.message || "An error occured",
          status: 'error',
          duration: 9000,
          position:'top-right',
          isClosable: true,
        })
      }
      if(response.success){
          setLoading(false);
          setUsers(response.data);
         return
       }
     }
    catch(error){
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: 'error',
        duration: 9000,
        position:'top-right',
        isClosable: true,
      })
    }
}

const initMakeAdmin = async(userId)=>{
  try{
    setLoading(true);
    const {data:response} = await axios.patch(SERVER_URL+'/user/'+userId,{isAdmin:true},{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    });
    if(response.error){
      toast({
        title: response.error || "An error occured",
        status: 'error',
        duration: 9000,
        position:'top-right',
        isClosable: true,
      })
    }
    if(!response.success){
      toast({
        title: response.message || "An error occured",
        status: 'error',
        duration: 9000,
        position:'top-right',
        isClosable: true,
      })
    }
    if(response.success){
        setLoading(false);
        toast({
          title: response.message || "User updated",
          status: 'success',
          duration: 9000,
          position:'top-right',
          isClosable: true,
        })
        initFilterSubmit();
       return
     }
   }
  catch(error){
    setLoading(false);
    toast({
      title: error.message || "An error occured",
      status: 'error',
      duration: 9000,
      position:'top-right',
      isClosable: true,
    })
  }
}

useEffect(()=>{  
  initFilterSubmit();
},[])

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <div class="overlay" style={{ display: loading ? "block" : "none" }}>
        <div class="spinner"></div>
        <br />
        Please Wait...
      </div>

      <Grid w="100%" p="10px">
        <TextFilter
          searchText={searchText}
          setSearchText={setSearchText}s
          searchTextLabel="Wallet Address"
          initFilterSubmit={initFilterSubmit}
        />
        {users.length>0 && <UsersTable users={users} initMakeAdmin={initMakeAdmin} />}
      </Grid>
    </Box>
  );
}