import React from "react";
import { Box, Flex, Image, Heading } from "@chakra-ui/react";
import LogoWhite from "assets/images/logo.png";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/web/index">
      <Box>
        <Flex>
          <Image
            height="auto"
            width="80px"
            src={LogoWhite}
            alt="Decentralized Sport Betting"
            mt="2"
          />
        </Flex>
      </Box>
    </Link>
  );
}
