/* eslint-disable */
import {
  Flex,
  TableContainer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo } from "react";
import {Link} from "react-router-dom";

export default function UsersTable(props) {
  const { users, initMakeAdmin} = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card w="100%" px="0px" overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Users Table
        </Text>
      </Flex>

      <TableContainer>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr>
              <Th borderColor={borderColor}>Address</Th>
              <Th borderColor={borderColor}>Email</Th>
              <Th borderColor={borderColor}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user, index) => {
             return (
              <Tr
                key={index}
                fontSize={{ sm: "14px" }}
                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                borderColor="transparent"
              >
                <Td>{user.address}</Td>
                <Td>{user.emailAddress}</Td>
                <Td>{!user.isAdmin && <button onClick={(e)=>{ e.preventDefault(); initMakeAdmin(user._id); }}>Make Admin</button>} </Td>
              </Tr>
             )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
