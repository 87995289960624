import React from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Image,
  Flex,
  Center,
  Container,
  Heading,
} from "@chakra-ui/react";
import { FiBell, FiShield, FiGlobe } from "react-icons/fi";

import Decentralized from "assets/images/blockchain.png";
import Payout from "assets/images/fast.png";
import Secured from "assets/images/protection.png";
import Transparent from "assets/images/transparent.png";
import Background from "assets/images/bg2.png";

const Feature = ({ title, text, image }) => {
  return (
    <Box boxShadow="dark-lg" p="6" rounded="md" bg="secondary.500">
      <Center>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color="brand.500"
          rounded={"full"}
          bg={"gray.100"}
          mb={1}
          shadow="md"
        >
          <Image src={image} width="100%" height="auto"/>
        </Flex>
      </Center>
      <Center>
        <Text
          color="white"
          textAlign="center"
          fontSize={{
            base: "sm",
            sm: "md",
            md: "md",
            lg: "lg",
          }}
          fontFamily="Spantran"
          fontWeight="300"
        >
          {title}
        </Text>
      </Center>
      <Text
        color="white"
        textAlign="center"
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
        fontFamily="ManropeLight"
      >
        {text}
      </Text>
    </Box>
  );
};

const HowItWorksHeading = ({ children }) => {
  return (
    <Heading
      as={"h3"}
      textAlign={"center"}
      color="white"
      fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
      fontFamily="Spantran"
      fontWeight="400"
    >
      {children}
    </Heading>
  );
};

const HowItWorksText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color="white"
      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
      fontFamily="ManropeLight"
      mt="4"
    >
      {children}
    </Text>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box
      p={4}
      background="#070b24"
      /*backgroundImage={`url(${Background })`}*/
      backgroundPosition="center center"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <Center width="100%" minH="50px">
        <Container columns={{ base: 1, md: 1 }} width="80%" mt="5">
          <HowItWorksHeading>
            Amazing{" "}
            <Text as="span" color="secondary.500">
            Betralix
            </Text>{" "}
            Features
          </HowItWorksHeading>
          <HowItWorksText>
          Betralix ensures anonymous, transparent betting and direct
            settlement to the user’s cryptocurrency wallet.
          </HowItWorksText>
        </Container>
      </Center>

      <Center width="100%" minH="300px" mt="5" mb="5">
        <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={10} width="80%">
          <Feature
            icon={<Icon as={FiBell} w={10} h={10} />}
            title={"Decentralized"}
            text=""
            image={Decentralized}
          />
          <Feature
            icon={<Icon as={FiShield} w={10} h={10} />}
            title={"Instant Payout"}
            text=""
            image={Payout}
          />
          <Feature
            icon={<Icon as={FiGlobe} w={10} h={10} />}
            title={"Secured"}
            text=""
            image={Secured}
          />
          <Feature
            icon={<Icon as={FiGlobe} w={10} h={10} />}
            title={"Transparent"}
            text=""
            image={Transparent}
          />
        </SimpleGrid>
      </Center>
    </Box>
  );
}
