import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
// Chakra imports
import {
  Box,
  Center,
  Button,
  Text,
  Image,
  VStack,
  TagLabel,
  HStack,
  Tag,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useClipboard,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiShare2, FiLink2, FiCopy } from "react-icons/fi";
import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, TelegramShare, EmailShare } from 'react-share-kit';
import DeboxLogo from "assets/images/deboxlogo.png";
import { DeboxAccessToken, ShareDeboxMessage } from "services/userService";
// Custom component
const { DateTime } = require("luxon");

export default function Info(props) {
  const location = useLocation();
  const toast = useToast();
  const { match } = props;
  const { isOpen: isShareOpen, onOpen: onShareOnOpen, onClose: onShareClose } = useDisclosure();
  const [deboxLoading, setDeboxLoading] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("🔥 "+match.team1.name+" takes on "+match.team2.name+" in a high-stakes "+match.league.name+" showdown! 🏆 Who will come out on top? Place your bets now and be part of the action! ⚽ #"+match.league.name+ " #Betralix"+ " #"+match.team1.name+ " #"+match.team2.name+" #BetNow");
  const [hashtags, setHashtags] = useState([match.team1.name, match.team2.name, match.league.name]);
  const { onCopy, hasCopied } = useClipboard(shareUrl);

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  }

  const calculateOdds = (teamStakes, totalStakes) => {
    let teamPercentage = (teamStakes * 100) / totalStakes;
    teamPercentage = 100 - teamPercentage;
    teamPercentage = teamPercentage / 100;
    let teamOdds = teamPercentage * totalStakes;
    teamOdds = teamOdds / teamStakes;
    teamOdds = teamOdds === 1 ? (2) : teamOdds;
    teamOdds = teamOdds < 1 ? (1 + teamOdds) : teamOdds;
    teamOdds = !isFinite(teamOdds) ? 1 : teamOdds;
    teamOdds = teamOdds % 1 === 0 ? parseInt(teamOdds) : teamOdds;
    return teamOdds.toFixed(2);
  };


  const handleShareDebox = async () => {
    try {
      //setLoading(true);
      const deboxAccessToken = localStorage.getItem("deboxAccessToken");
      if(!deboxAccessToken){
        //const response = await DeboxAuth();
        window.location.href = "https://app.debox.pro/oauth/authorize/?app_id=urtjHOpJqxVVDM13&grant_type=authorization_code&scope=payment&response_type=code&pay_info=loginTest&redirect_uri=https://betralix.com/app/matches";
      }
    } catch (error) {
      //setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const fetchAccessToken = async (code, userId) => {
    try {
      setDeboxLoading(true);
      const deboxAccessToken = localStorage.getItem("deboxAccessToken");
      setDeboxLoading(false);
      if(!deboxAccessToken){
        const response = await DeboxAccessToken(code, userId);
        if (!response || !response.success) {
        }
        if (response.success) {
          //const token = response.data.access_token;
          setDeboxLoading(true);
          await ShareDeboxMessage(
            "Don't Miss the Epic Clash: "+match.team1.name+" VS "+match.team2.name, 
            "🔥 "+match.team1.name+" takes on "+match.team2.name+" in a high-stakes "+match.league.name+" showdown! 🏆 Who will come out on top? Place your bets now and be part of the action! ⚽ #"+match.league.name+ " #Betralix"+ " #"+match.team1.name+ " #"+match.team2.name+" #BetNow"+" https://betralix.com/app/match/single/"+match._id);
            setDeboxLoading(false);
            toast({
              title: "Match has been shared to Debox group",
              status: "success",
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            window.location.href = process.env.REACT_APP_SITE_URL + "/app/matches";
        }
      }
    } catch (error) {
      setDeboxLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shareUrl === "") return;
    onCopy();
  }, [shareUrl]);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // Extract 'code' and 'user_id' from the URL parameters
    const code = searchParams.get('code');
    const userId = searchParams.get('user_id');
    if (code && userId) {
      // Exchange the authorization code for an access token
      fetchAccessToken(code, userId);
    }
  }, []);

  return (
    <>
      <Modal isOpen={isShareOpen} onClose={onShareClose}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">Share Match</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Box width="100%" mb="10">
              <Flex gap="2" flexDirection="row" flexWrap="wrap">
                <FacebookShare url={shareUrl}
                  quote={title}
                  hashtag={hashtags} />
                <TwitterShare
                  url={shareUrl}
                  title={title}
                  hashtags={hashtags} />
                <LinkedinShare
                  url={shareUrl} />
                <WhatsappShare
                  url={shareUrl}
                  title={title}
                  separator=":: "
                />
                <TelegramShare url={shareUrl} />
                <Box background="#24c363" height="65px" width="65px" onClick={handleShareDebox} opacity={deboxLoading? "0.6":"1"}><Center height="65px" width="65px"><Image src={DeboxLogo}  height="60px" width="60px" /></Center></Box>
                <EmailShare
                  url={shareUrl}
                  subject={title}
                  body="You can trade with me by visiting my Trade page"
                />
                <Link to={shareUrl}><FiLink2 mt="2" size="50" color="white" /></Link>
              </Flex>

              <InputGroup mt="4">
                <Input color="white" value={shareUrl} isReadOnly />
                <InputRightElement>
                  <Button onClick={(e) => { setCopied(true) }} size="sm">
                    <FiCopy />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text textAlign={'center'}>{copied && hasCopied ? 'Copied' : ''}</Text>
            </Box>
          </ModalBody>

        </ModalContent>
      </Modal>
      <VStack
        width="100%"
        minHeight="100"
        borderRadius="10"
        background="brand.500"
      >
        <Box
          width="100%"
          p="2"
          borderTopRadius="10"
          borderBottomWidth={1}
          borderBottomColor={"brand.800"}
          background="secondary.300"
        >
          <Flex justifyContent="space-between">
            <Box>
              <Flex>
                <Tag size={"sm"} variant="subtle" colorScheme="cyan">
                  <TagLabel fontSize={{sm:"10px", md:"12px"}}>Soccer</TagLabel>
                </Tag>{" "}
                <Text color="white" fontSize={{sm:"sm", md:"md"}}> &nbsp;{formatTime(match.matchTimestamp)}</Text>
              </Flex>
            </Box>
            <Box>
              <FiShare2 color="white" size="20" onClick={() => {
                setShareUrl(process.env.REACT_APP_SITE_URL + "/app/match/single/" + match._id);
                setTitle("Win massively by staking your DBX on "+match.team1.name+" VS "+match.team2.name);
                onShareOnOpen();
              }} />
            </Box>
          </Flex>
        </Box>
        <Box
          width="100%"
          p="2"
          borderBottomWidth={1}
          borderBottomColor={"brand.800"}
        >
          <HStack columns={3} width="100%">
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%">
                  <Text color="white" fontWeight="bold" textAlign="center" fontSize={{sm:"md", md:"lg"}}>
                    {match.team1.name}
                  </Text>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Home
                  </Text>
                </Box>
                <Box width="100%">
                  <Center>
                    <Image
                      src={match.team1.logo}
                      width="80%"
                      height="auto"
                      alt="team 1"
                    />
                  </Center>
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.team1Stakes)} DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.team1Stakes, (match.team1Stakes + match.team2Stakes + match.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%" alignContent={"center"} alignItems={"center"}>
                  <Center>
                    <Tag size={"md"} variant="outline" colorScheme="blue">
                      <TagLabel>VS</TagLabel>
                    </Tag>
                  </Center>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Draw
                  </Text>
                </Box>
                <Box width="100%">
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.drawStakes)} DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.drawStakes, (match.team1Stakes + match.team2Stakes + match.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box width="100%">
              <VStack width="100%">
                <Box width="100%">
                  <Text color="white" fontWeight="bold" textAlign="center" fontSize={{sm:"md", md:"lg"}}>
                    {match.team2.name}
                  </Text>
                </Box>
                <Box width="100%">
                  <Text color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    Away
                  </Text>
                </Box>
                <Box width="100%">
                  <Center>
                    <Image
                      src={match.team2.logo}
                      width="80%"
                      height="auto"
                      alt="team 1"
                    />
                  </Center>
                  <Text mt="2" color="white" textAlign="center" fontSize={{sm:"sm", md:"md"}}>
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(match.team2Stakes)} DBX
                    <Text fontSize={{sm:"sm", md:"md"}}>{calculateOdds(match.team2Stakes, (match.team1Stakes + match.team2Stakes + match.drawStakes))}X</Text>
                  </Text>
                </Box>
              </VStack>
            </Box>
          </HStack>

          <HStack
            columns={3}
            width="100%"
            mt="4"
            pt="2"
            borderTopWidth={1}
            borderTopColor="secondary.300"
          >
            <Box width="100%">
              <Center>
                <Link
                  to={{ pathname: "/app/match/" + match._id + "/" + match.team1._id }}
                >
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                    fontSize={{sm:"sm", md:"md"}}
                  >
                    Home Win
                  </Button>
                </Link>
              </Center>
            </Box>
            <Box width="100%">
              <Center>
                <Link to={{ pathname: "/app/match/" + match._id + "/draw" }}>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                    fontSize={{sm:"sm", md:"md"}}
                  >
                    Draw
                  </Button>
                </Link>
              </Center>
            </Box>
            <Box width="100%">
              <Center>
                <Link
                  to={{ pathname: "/app/match/" + match._id + "/" + match.team2._id }}
                >
                  <Button
                    size="sm"
                    colorScheme="teal"
                    color="white"
                    variant="outline"
                    fontSize={{sm:"sm", md:"md"}}
                  >
                    Away Win
                  </Button>
                </Link>
              </Center>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </>
  );
}
