import axiosInstance from "./axiosInstance"; // Adjust the path accordingly
import { ethers, BrowserProvider } from "ethers";
import faucet from "blockchain/Faucet.json";

const CONTRACT_ADDRESS = "0x75D72e523d04E0715EE2597CdF6F5205fb662E6A";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ContractGetTokens = async (walletConnect, amount) => {
   try{
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);

    const signer = await ethersProvider.getSigner();
    // The Contract object
    const FaucetContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      faucet.abi,
      signer
    );
    let wei = ethers.parseEther(String(amount));
    const transaction = await FaucetContract.withdraw(wei);
    await transaction.wait();
    return true;
   } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
}

const CheckFaucetWithdrawal = async (token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/faucet/checkWithdrawal",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const Withdraw = async (amount, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/faucet",
      {
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export {
  ContractGetTokens,
  CheckFaucetWithdrawal,
  Withdraw
};
