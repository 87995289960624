import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Terms() {
  return (
    <Box minH="100vh"  background="#070b24">
      <Helmet>
        <title>Terms | Terms and Conditions</title>
        <meta
          name="description"
          content="Read our terms and conditions carefully before using decentralized betting platform. Our terms and conditions outline the rules, regulations, and responsibilities that govern the use of our service. By using our service, you agree to abide by our terms and conditions."
        />
        <meta
          name="keywords"
          content="Create bet, become bet maker, p2p match betting, p2p bettings"
        />
      </Helmet>
      <Header />
         
      <VStack spacing="2" background="brand.500">
      <Box>
        <Heading as="h3" mt="10" color="white">
          Terms of Service
        </Heading>
      </Box>
      <Box p={{ base: "4", sm: "10", md: "20" }} fontSize={{ base: "md", sm: "lg", md: "xl" }} color="white">
        <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
          Effective Date: 17th of May, 2023
        </Text>

        <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} color="white">
          Welcome to <b>Betralix.com</b>, operated by <b>Tradersfield Ltd</b>. These Terms of Service ("Terms") govern your use of our decentralized betting platform. Please read these Terms carefully before using our website or services. By accessing or using betralix.com, you agree to be bound by these Terms.
        </Text>

        <OrderedList spacing={4} pl={4} mt="4" color="white">
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Introduction
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Welcome to Betralix.com (the "Platform"), owned and operated by Tradersfield Ltd ("Tradersfield," "we," "us," or "our"). By accessing or using our Platform, you agree to comply with and be bound by these Terms of Service ("Terms"). Please read these Terms carefully before accessing or using the Platform. If you do not agree to these Terms, you may not access or use the Platform.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Definitions
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Platform:</b> Refers to Betralix.com and all its associated services, features, and functionalities.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>User:</b> Refers to any individual or entity accessing or using the Platform
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Content:</b> Refers to any text, images, videos, audio, or other materials uploaded, posted, transmitted, or otherwise made available on the Platform.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Decentralized Betting:</b> Refers to the betting activities facilitated on the Platform using decentralized technology, including but not limited to blockchain.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Account:</b> Refers to the user account created by registering on the Platform.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Wallet:</b> Refers to the digital wallet used by users to store and transact cryptocurrencies on the Platform.
            </Text>
          </ListItem>


          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
               Eligibility
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            By accessing or using the Platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are accessing or using the Platform on behalf of an entity, you further represent and warrant that you have the authority to bind such entity to these Terms.
            </Text>
          </ListItem>


          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Registration and Accounts
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
             <b>Account Creation:</b> In order to access certain features of the Platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
             <b>Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
             <b>Account Termination:</b> We reserve the right to suspend or terminate your account at any time and for any reason without notice.
            </Text>
          </ListItem>


          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Platform Use
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Decentralized Nature:</b> You acknowledge and agree that the Platform operates on decentralized technology, and transactions made on the Platform are irreversible. We are not responsible for any loss of funds due to user error or technical issues.
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Prohibited Activities:</b> You agree not to engage in any of the following prohibited activities:
              <ul>
                 <li>Violating any applicable laws or regulations. </li>
                 <li>Interfering with the proper functioning of the Platform. </li>
                 <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
                 <li>Uploading, posting, transmitting, or otherwise making available any Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</li>
                 <li>Engaging in any activity that could damage, disable, overburden, or impair our servers or networks.</li>
              </ul>
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              <b>Intellectual Property:</b>All Content on the Platform, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, is the property of Tradersfield or its licensors and is protected by copyright, trademark, and other intellectual property laws.
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              Disclaimer of Warranties
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
              THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Limitation of Liability
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            IN NO EVENT SHALL TRADERSFIELD, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, OR INTERRUPTION OF BUSINESS, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF TRADERSFIELD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Indemnification
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            You agree to indemnify, defend, and hold harmless Tradersfield, its officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in any way related to your use or misuse of the Platform, your violation of these Terms, or your violation of any rights of any other person or entity.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Governing Law and Jurisdiction
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            These Terms shall be governed by and construed in accordance with the laws of Hong Kong. You agree to submit to the exclusive jurisdiction of the courts located in Hong Kong for the resolution of any disputes arising out of or related to these Terms or your use of the Platform.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Changes to Terms
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            We reserve the right to modify or update these Terms at any time without prior notice. Your continued use of the Platform after any such changes constitutes your acceptance of the new Terms. It is your responsibility to review these Terms periodically for changes.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Contact Us
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            If you have any questions or concerns about these Terms, please contact us at info@betralix.com.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontWeight="bold" fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Entire Agreement
            </Text>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            These Terms constitute the entire agreement between you and Tradersfield regarding your use of the Platform and supersede all prior or contemporaneous understandings and agreements, whether oral or written, relating to the subject matter of these Terms.
            </Text>
          </ListItem>
        </OrderedList>
      </Box>
    </VStack>
      
    </Box>
  );
}
