import React from "react";

// Chakra imports
import { Flex, Image, Heading,  useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import Logo from "assets/images/logowhite.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='start' direction='column' p="0" pt="5" pb="5" background="brand.500" >
      <Flex pl="5"><Image src={Logo} width="40px" height="40px" alt="Betralix" /><Heading color="#fff" ml="2" mt="1" fontSize={{base:'md',sm:'lg',md:'xl',lg:'2xl'}}>Betralix</Heading></Flex>
    </Flex>
  );
}

export default SidebarBrand;
