import { ethers, BrowserProvider } from "ethers";
import staking from "blockchain/Staking.json";
import token from "blockchain/IBEP20.json";

const CONTRACT_ADDRESS = "0xd532f9e3F82Ee90Ad4930c27E5468be4CA810eF4";
const TOKEN_ADDRESS = "0xA872679b5D96E30D924A0AB0952F54D72b26EEf5";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const totalStaked = async (walletConnect) => {
  try {
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const totalStaked = await StakeContract.getTotalLockedValue();
    const wei = ethers.parseEther(totalStaked);
    return wei;
  } catch (error) {
    return 0;
  }
};

const totalStakers = async (walletConnect) => {
  try {
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );

    const stakers = await StakeContract.getNumberOfStakers();
    return parseInt(stakers);
  } catch (error) {
    return 0;
  }
};

const stakeOf = async (walletConnect, address) => {
  try {
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const totalStake = await StakeContract.getStakerTotalStakedValue(address);
    const wei = ethers.formatEther(totalStake);
    return wei;
  } catch (error) {
    return 0;
  }
};

const createStake = async (walletConnect, amount) => {
  try {
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );

    const Token = new ethers.Contract(TOKEN_ADDRESS, token.abi, signer);
    let wei = ethers.parseEther(amount);
    let transaction = await Token.approve(CONTRACT_ADDRESS, wei);
    await transaction.wait();
    transaction = await StakeContract.stake(wei);
    let receipt = await transaction.wait();
    return { success: true, transactionHash: receipt.transactionHash };
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const removeStake = async (walletConnect) => {
   try{

    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const transaction = await StakeContract.unstake();
    let receipt = await transaction.wait();
    return { success: true, transactionHash: receipt.transactionHash };
   } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const rewardOf = async (walletConnect, address) => {
  try {
    if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const StakeContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      staking.abi,
      signer
    );
    const reward = await StakeContract.calculateReward(address);
    const ether = ethers.formatEther(reward, { commify: true, pad: true });
    return ether;
  } catch (error) {
    return 0;
  }
};

/*const withdraw = async (amount) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const Stake = new ethers.Contract(CONTRACT_ADDRESS, staking.abi, signer);
  const wei = ethers.parseEther(String(amount));
  try {
    const transaction = await Stake.withdraw(wei);
    await transaction.wait();
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};*/

/*const createStakeHolder = async (amount) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const Stake = new ethers.Contract(CONTRACT_ADDRESS, staking.abi, signer);
  const Token = new ethers.Contract(TOKEN_ADDRESS, token.abi, signer);
  const wei = ethers.parseEther(String(amount));
  try {
    let transaction = await Token.approve(CONTRACT_ADDRESS, wei);
    await transaction.wait();
    transaction = await Stake.createStakeHolder(wei);
    await transaction.wait();
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};*/

/*const withdrawReward = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const Stake = new ethers.Contract(CONTRACT_ADDRESS, staking.abi, signer);
  try {
    const transaction = await Stake.withdrawReward();
    await transaction.wait();
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};*/

export {
  totalStakers,
  totalStaked,
  stakeOf,
  createStake,
  //createStakeHolder,
  rewardOf,
  //withdrawReward,
  removeStake,
};
