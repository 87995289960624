import {
  Grid,
  GridItem,
  Box,
  Flex,
  Button,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'

import { GetFixture } from "services/matchService";
import socketClient from "socket.io-client";
import Card from "components/card/Card.js";
import Team from "views/admin/match/components/Team";
import { ContractCreateMatch } from "services/matchService";
const { DateTime } = require("luxon");

//const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;

export default function CreateMatch() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState(null);
  const [initBet, setInitBet] = useState(null);
  const [fixtureId, setFixtureId] = useState("");
  const toast = useToast();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorDate = useColorModeValue("secondaryGray.600", "white");

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  };

  const handleGetFixture = async (e) => {
    setLoading(true);
    const response = await GetFixture(fixtureId, token);
    setLoading(false);
    if (response.error && response.error !== "") {
      toast({
        title: response.error || "something went wrong",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    if (response.success === false) {
      toast({
        title: response.message || "something went wrong",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    setGame(response.data);
    onOpen();
    return;
  };

  const initCreateMatch = async (e) => {
    setLoading(true);
    try {
      const walletConnect = {isConnected, walletProvider };
      const response = await ContractCreateMatch(
        walletConnect,
        game._id,
        game.team1._id,
        game.team2._id,
        game.matchTimestamp
      );

      if(response.success){
      toast({
        title: "Game created on blockchain successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onClose();
      setLoading(false);
     }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  /*useEffect(() => {
    const initBet = socketClient(SOCKET_ENDPOINT + "/initBet");
    setInitBet(initBet);
    initBet.emit("initcreatebetroom", "room");
    initBet.on("gamecreated", () => {
      toast({
        title: "Game created on blockchain successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    });
  }, []);
  */

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Push Match to Blockchain</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card p="0px">
              <Flex
                align={{ sm: "flex-start", lg: "center" }}
                justify="space-between"
                w="100%"
                px="22px"
                py="18px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  Match Details
                </Text>

                <Text
                  ms="auto"
                  fontWeight="700"
                  fontSize="sm"
                  color={textColorDate}
                >
                  {game !== null && formatTime(game.matchTimestamp)}
                </Text>

                <Button variant="action">
                  {game !== null && game.sport.name}
                </Button>
              </Flex>

              {game !== null && (
                <>
                  <Team
                    teamName={game.team1.name}
                    teamLogo={game.team1.logo}
                    teamCountryName={game.team1.countryName}
                    position="Home"
                  />
                  <Team
                    teamName={game.team2.name}
                    teamLogo={game.team2.logo}
                    teamCountryName={game.team2.countryName}
                    position="Away"
                  />
                </>
              )}
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="brand"
              isLoading={loading}
              isLoadingText={"Wait.."}
              onClick={(e) => {
                e.preventDefault();
                initCreateMatch();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid>
          <GridItem w="100%" h="400px">
            <Box
              overflow="hidden"
              width={{ base: "100%", sm: "100%", md: "50%" }}
            >
              <Card w="100%" p="20px">
                <form>
                  <FormControl>
                    <FormLabel htmlFor="fixture">Fixture ID</FormLabel>
                    <Input
                      id="fixture"
                      type="text"
                      onChange={(e) => setFixtureId(e.target.value)}
                    />
                    <FormHelperText>
                      Enter Fixture ID From API-FOOTBALL.
                    </FormHelperText>
                  </FormControl>
                  <Button
                    me="100%"
                    mb="50px"
                    w="140px"
                    minW="140px"
                    mt={{ base: "20px", "2xl": "auto" }}
                    variant="brand"
                    fontWeight="500"
                    isLoading={loading}
                    isLoadingText={"Wait.."}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGetFixture();
                    }}
                  >
                    Get Fixture
                  </Button>
                </form>
              </Card>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}
