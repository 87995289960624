import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  Image,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md"
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/bg4.png";
import Logo from "assets/images/logo.png";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers/react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "utils/userSlice";
import { UserLogin } from "services/userService";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const toggle = () => setIsOpen(!isOpen);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;

  const login = async () => {
    try {
      setLoading(true);
      const response = await UserLogin(address);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {

        if(!response.data.isAdmin){
          toast({
            title: "You are not allowed here",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        dispatch(setUser(response.data));
        navigate("/admin/default");
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };


  const handleConnect = async () => {
    await open();  
    try {
      await open({
        networkId: 97,
      });
    } catch (error) {
      console.error("Failed to connect and switch network", error);
    }
 };

  const handleLogout = async () => {
    await disconnect();
    dispatch(setUser({}));
  };

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 3) + ".." + ethereumAddress.slice(-3)
        : ethereumAddress;

    return displayedAddress;
  };

  useEffect(() => {
    if (isConnected && !isLoggedIn) {
      login();
    }
   
  }, [isConnected]);

  return (
    <>
      <Helmet>
        <title>Login | Access your account</title>
        <meta
          name="description"
          content="Log in to your account to manage your virtual card, access your information, and enjoy exclusive offers"
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "100%" }}
          w="100%"
          h="100%"
          mb="10"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
            <Stack
              spacing={8}
              mx={"auto"}
              w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
            >
              <Stack align={"center"}>
                <Image src={Logo} alt="Betralix" height="auto" width="60px" />
                {/*<Heading
                  fontSize={{ base: "19px", sm: "22px", md: "23px" }}
                  mb="5"
                >
                  Swaptrex
               </Heading>*/}
                <Text
                  fontFamily="ManropeLight"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                >
                  We are happy to see you come back
                </Text>
                <Heading
                  fontFamily="ManropeSemiBold"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                  textAlign={"center"}
                >
                  Sign In
                </Heading>
              </Stack>
              <Box p={2} width="100%" textAlign="center">
                {
                !isConnected &&
                <Button
                  onClick={handleConnect}
                  class="banner-btn"
                  size="md"
                  rounded={"full"}
                  color="brand.500"
                  fontFamily="ManropeLight"
                  _hover={{
                    bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
                  }}
                >
                  Connect
                  <Icon
                    as={MdArrowForward}
                    width="20px"
                    height="20px"
                    color="white"
                  />
                </Button>
                }

               {
                isConnected &&
                <Button
                  onClick={handleLogout}
                  class="banner-btn"
                  size="md"
                  rounded={"full"}
                  color="brand.500"
                  fontFamily="ManropeLight"
                  _hover={{
                    bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
                  }}
                >
                  Disconnect
                  <Icon
                    as={MdArrowForward}
                    width="20px"
                    height="20px"
                    color="white"
                  />
                </Button>
                }

              </Box>
            </Stack>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default Login;
