import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Container,
  Box,
  Center,
  HStack,
  VStack,
  Heading,
  Badge,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { GetUser, GetReferrals } from "services/userService";
import { useSelector } from "react-redux";
import Bg1 from "assets/images/bg3transparent.png";
import Paginate from "components/navigation/Paginate.js";

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referrals, setReferrals] = useState([]);

  const [user, setUser] = useState(null);
  const getUser = async () => {
    try {
      const response = await GetUser(token);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setUser(response.data);
      }
    } catch (error) {}
  };

  const getReferrals = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const response = await GetReferrals(pagination, token);
      setLoading(false);
      if (response.error) {
        return;
      }
      if (!response.success) {
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setReferrals(response?.data?.referrals);
        if (response.data?.referral.length === 0) {
          setFinished(true);
        }
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 5) + "..." + ethereumAddress.slice(-5)
        : ethereumAddress;

    return displayedAddress;
  };

  useEffect(() => {
    if (token !== null && token !== undefined) {
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined) {
      getReferrals();
    }
  }, [token, from]);

  return (
    <>
      <Container
        maxW={"100%"}
        py={12}
        backgroundImage={`url(${Bg1})`}
        backgroundSize="100% auto"
        backgroundRepeat="no-repeat"
      >
        <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">
          <Box width="100%" mt="5">
            <Box width="100%" mt="7" mb="7" textAlign="center">
              <Heading
                fontSize={{ base: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
                fontFamily="Spantran"
                fontWeight="500"
                color="white"
              >
                {" "}
                DBX Airdrop Program{" "}
              </Heading>
              <Text fontSize="18" color="white">
                We are thrilled to announce our exclusive Airdrop Campaign.
              </Text>
              <Text fontSize="18" color="white">
                This is your chance to get in on the action and earn DBX tokens
                simply by participating and accumulating points!
              </Text>
            </Box>
          </Box>

          <Box width="100%" mt="5" textAlign="center">
            <Text fontSize="18" color="secondary.600">
              Claim DBX Testnet tokens from{" "}
              <Link to="/app/faucet">
                <u>DBX Faucet</u>
              </Link>{" "}
              and Testnet BNB from{" "}
              <a href="https://www.bnbchain.org/en/testnet-faucet">
                <u>BNB Faucet</u>
              </a>
            </Text>
          </Box>

          <Center width="100%" mt="5">
            <Card
              width={{ sm: "90%", md: "60%" }}
              background="brand.500"
              p="4"
              pt="6"
              pb="6"
            >
              <Center width="100%">
                <Box width={{ sm: "100%", md: "100%" }}>
                  <Box width="100%">
                    <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                      Place Bet on Matches
                    </Heading>
                    <Text color="white">
                    Earn 100 points every time you participate in match betting. Accumulate more points by placing bets on different matches.
                    </Text>
                  </Box>
                  <HStack columns={{ sm: 2, md: 2 }} mt="2">
                    <Box width="50%" textAlign="left">
                      <Badge mt="2">Recurring</Badge>
                    </Box>
                    <Box width="50%" textAlign="right">
                      <Link to="/app/matches">
                        <Button>Start</Button>
                      </Link>
                    </Box>
                  </HStack>
                </Box>
              </Center>
            </Card>
          </Center>

          <Center width="100%" mt="5">
            <Card
              width={{ sm: "90%", md: "60%" }}
              background="brand.500"
              p="4"
              pt="6"
              pb="6"
            >
              <Center width="100%">
                <Box width={{ sm: "100%", md: "100%" }}>
                  <Box width="100%">
                    <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                      Stake DBX Tokens
                    </Heading>
                    <Text color="white">
                    Earn 100 points by staking your DBX Testnet tokens in the DBX staking program. Participate in staking every 24 hours to earn additional points.
                    </Text>
                  </Box>
                  <HStack mt="2">
                    <Box width="50%" textAlign="left">
                      <Badge mt="2">Recurring</Badge>
                    </Box>
                    <Box width="50%" textAlign="right">
                      <Link to="/app/staking">
                        <Button>Start</Button>
                      </Link>
                    </Box>
                  </HStack>
                </Box>
              </Center>
            </Card>
          </Center>

          <Center width="100%" mt="5">
            <Card width={{ sm: "90%", md: "60%" }} background="brand.500" p="4">
              <Center width="100%">
                <Box width={{ sm: "100%", md: "100%" }}>
                  <Box width="100%">
                    <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                      Update and Verify Profile Email
                    </Heading>
                    <Text color="white">
                      Earn 100 points by completing your profile. Update and verify your email in the profile section to receive your points.
                    </Text>
                  </Box>
                  <HStack mt="2">
                    <Box width="50%" textAlign="left">
                      <Badge mt="2">One Time</Badge>
                    </Box>
                    <Box width="50%" textAlign="right">
                      {user !== null && user.emailVerified ? (
                        <Button disabled="true">Done</Button>
                      ) : (
                        <Link to="/app/profile">
                          <Button>Start</Button>
                        </Link>
                      )}
                    </Box>
                  </HStack>
                </Box>
              </Center>
            </Card>
          </Center>

          <Center width="100%" mt="5">
            <Card width={{ sm: "90%", md: "60%" }} background="brand.500" p="4">
              <Center width="100%">
                <Box width={{ sm: "100%", md: "100%" }}>
                  <Box width="100%">
                    <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                      Complete Social Engagement Tasks
                    </Heading>
                    <Text color="white">
                      Earn additional points for each completed task, including sharing platform updates and joining the community. You can earn up to 2,000 points in total
                    </Text>
                  </Box>
                  <HStack mt="2">
                    <Box width="50%" textAlign="left">
                      <Badge mt="2">Recurring</Badge>
                    </Box>
                    <Box width="50%" textAlign="right">
                      <a
                        href="https://taskon.xyz/campaign/detail/989266186"
                        target="_blank"
                      >
                        <Button>Start</Button>
                      </a>
                    </Box>
                  </HStack>
                </Box>
              </Center>
            </Card>
          </Center>

          <Center width="100%" mt="5">
            <Card width={{ sm: "90%", md: "60%" }} background="brand.500" p="4">
              <Center width="100%">
                <Box width={{ sm: "100%", md: "100%" }}>
                  <VStack>
                    <Box width="100%">
                      <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                        Referral Task
                      </Heading>
                      <Text color="white">
                        Earn 100 points for each user you invite to Betralix. Share your referral code to invite more users and earn rewards.
                      </Text>
                    </Box>

                    <Box width="100%" mt="3">
                      <Heading fontSize={{ sm: "md", md: "lg" }} color="white">
                        {user !== null
                          ? "https://betralix.com?ref=" + user.referralCode
                          : ""}
                      </Heading>
                    </Box>

                    <Box width="100%" mt="3" textAlign={"center"}>
                      <Heading fontSize={{ sm: "lg", md: "xl" }} color="white">
                        Invited Users
                      </Heading>
                      <Box mt="2">
                        {loading ? (
                          <Loading />
                        ) : (
                          <Box width="100%" mt="2" mb="2" textAlign={"center"}>
                            {referrals.length > 0 &&
                              referrals.map((referral, id) => (
                                <Text color="white">
                                  {ethereumAddressDisplay(referral.address)}
                                </Text>
                              ))}
                          </Box>
                        )}

                        {referrals.length > 0 && (
                          <Box width="100%" mt="5" textAlign="center">
                            <Paginate
                              pageCount={pageCount}
                              setFrom={setFrom}
                              getRecords={getReferrals}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </VStack>
                </Box>
              </Center>
            </Card>
          </Center>
        </Box>
      </Container>
    </>
  );
}

const Loading = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
