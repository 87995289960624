import React from "react";
import {Box} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Banner from "components/banners/Banner.js";
import Features from "./components/Features.js";
import FAQs from "./components/FAQs.js";
import About from "./components/About.js";
import HowItWorks from "./components/HowItWorks.js";

export default function Home() {

  return (
    <Box minH='100vh'>
      <Helmet>
        <title>Betralix</title>
        <meta name="description" content="Decentralized Sport Betting with Trustless Protocol: Fastest way to organize and place bets on contest like Soccer, NBA, UFC, and other competitive events" />
      </Helmet>
       <Banner />
       <About />
       <HowItWorks />
       <Features />
       <FAQs />
    </Box>
  );
}