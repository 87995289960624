import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  Checkbox,
  InputGroup,
  InputRightAddon,
  Button,
  SimpleGrid,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
//import socketClient from "socket.io-client";
import { useSelector } from "react-redux";
import {
  totalStaked,
  stakeOf,
  createStake,
  rewardOf,
  removeStake,
} from "services/stakeService";
//const token = localStorage.getItem("authToken");

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);

  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [assetStaked, setAssetStaked] = useState(0);
  const [step, setStep] = useState(1);
  const [terms, setTerms] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userStake, setUserStake] = useState(0);
  const [userReward, setUserReward] = useState(0);

  const getStakeOf = async () => {
    try {
      const walletConnect = { isConnected, walletProvider };
      const stake = await stakeOf(walletConnect, address);
      setUserStake(stake);
    } catch (error) {}
  };

  const getTotalStaked = async () => {
    try {
      const walletConnect = { isConnected, walletProvider };
      const staked = await totalStaked(walletConnect);
      setAssetStaked(staked);
    } catch (error) {}
  };

  const getRewardOf = async () => {
    try {
      const walletConnect = { isConnected, walletProvider };
      const reward = await rewardOf(walletConnect, address);
      setUserReward(reward);
    } catch (error) {}
  };

  const stake = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (terms !== true) {
        toast({
          title: "You must agree to terms before staking",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const walletConnect = { isConnected, walletProvider };
      const response = await createStake(walletConnect, amount);

      if (response.success) {
        toast({
          title: "Staking successful",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        getTotalStaked();
        getStakeOf();
        getRewardOf();
      }
      //initBet.emit("joinroom", address.toUpperCase());
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const unstake = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const walletConnect = { isConnected, walletProvider };
      const response = await removeStake(walletConnect);

      if (response.success) {
        toast({
          title: "Unstaking successful",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        getTotalStaked();
        getStakeOf();
        getRewardOf();
      }
      //initBet.emit("joinroom", address.toUpperCase());
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };
 
 useEffect(() => {
    if (address) {
      getTotalStaked();
      getStakeOf();
      getRewardOf();
    }
  }, [address, chainId]);

  /*useEffect(() => {
    if (address) {
      initBet.on(address.toUpperCase(), (data) => {
        if (data.hasOwnProperty("type") && data.type === "tokenstaked") {
          toast({
            title: "Staking successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setLoading(false);
          getTotalStaked();
          getStakeOf();
          getRewardOf();
        }
        if (data.hasOwnProperty("type") && data.type === "tokenunstaked") {
          toast({
            title: "Unstaking successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setLoading(false);
          getTotalStaked();
          getStakeOf();
          getRewardOf();
        }
      });
      getTotalStaked();
      getStakeOf();
      getRewardOf();
    }
  }, [address]);*/

  return (
    <>
      <Box pt={{ base: "10px", md: "20px", xl: "20px" }} width="100%">
        <Box width="100%" mt="5" mb="5">
          <Heading color="white" size="md">
            Staking
          </Heading>
        </Box>

        <SimpleGrid columns={{ base: 2, sm: 2, md: 4, lg: 4 }} spacing={2}>
          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Staked
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(userStake)}{" "}
                DBX
              </Text>
              {userStake > 0 && (
                <Text
                  color="white"
                  textAlign="center"
                  textDecoration={"underline"}
                  onClick={unstake}
                >
                  unstake
                </Text>
              )}
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Rewards
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(userReward)}{" "}
                DBX
              </Text>
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Total Locked Value
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(assetStaked)}{" "}
                DBX
              </Text>
            </Box>
          </Box>

          <Box className="trade-record-card" minHeight="70">
            <Box width="100%" minHeight="70" pt="3">
              <Text color="white" textAlign="center">
                APR
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                15%
              </Text>
            </Box>
          </Box>
        </SimpleGrid>

        <Box width="100%" mt="5">
          <Box width="100%" mt="7" mb="7" textAlign="center">
            <Heading size="sm" color="white">
              {" "}
              Stake Your DBX{" "}
            </Heading>
          </Box>
          <ol className="FormStepList">
            <li className="FormStepList-item">
              <span className={`FormStepIcon ${step === 1 ? "is-passed" : ""}`}>
                <span className="FormStepIcon-circle">1</span>
                <Text color="white" fontSize="14">
                  Instruction
                </Text>
              </span>
            </li>

            <li className="FormStepList-item">
              <span
                className={`FormStepIcon ${step === 1 ? "is-active" : ""} ${
                  step === 2 ? "is-passed" : ""
                }`}
              >
                <span className="FormStepIcon-circle">2</span>
                <Text color="white">Amount</Text>
              </span>
            </li>

            <li className="FormStepList-item">
              <span
                className={`FormStepIcon FormStepIcon--last ${
                  step === 2 ? "is-active" : ""
                }`}
              >
                <span className="FormStepIcon-circle">3</span>
                <Text color="white">Confirm &amp; Submit</Text>
              </span>
            </li>
          </ol>
        </Box>

        {step === 1 && (
          <Box width="100%">
            <Box width="100%">
              <Heading size="md" color="white">
                Instruction
              </Heading>
            </Box>
            <SimpleGrid
              columns={{ base: 1, sm: 1, md: 3, lg: 3 }}
              spacing={4}
              mt="5"
              mb="10"
            >
              <Box>
                <Heading size="sm" color="white">
                  1. Connect Wallet
                </Heading>
                <Text color="white">
                  If wallet not connected, click the "Connect" button at the top
                  right corner
                </Text>
              </Box>
              <Box>
                <Heading size="sm" color="white">
                  2. DBX available to deposit
                </Heading>
                <Text color="white">Enter amount of DBX you want to stake</Text>
              </Box>
              <Box>
                <Heading size="sm" color="white">
                  3. Approve Transaction
                </Heading>
                <Text color="white">
                  Approve the Dapp to deduct DBX balance from your wallet
                </Text>
              </Box>
            </SimpleGrid>

            <Box width="100%" mt="5" mb="5">
              <Box with="100%" textAlign="center">
                <Checkbox
                  onChange={(e) => {
                    // The event object (e) contains information about the checkbox state
                    const newCheckedState = e.target.checked;
                    if (newCheckedState) {
                      setTerms(true);
                    } else {
                      setTerms(false);
                    }
                  }}
                >
                  <Text color="white" textAlign="center">
                    Do you agree to stake your DBX tokens
                  </Text>
                </Checkbox>
              </Box>
            </Box>
            <Box with="100%" textAlign="center">
              <Button
                variant={"outline"}
                size="sm"
                color="white"
                onClick={(e) => {
                  if (!terms) {
                    toast({
                      title: "You need to accept terms to proceed",
                      status: "error",
                      duration: 9000,
                      position: "top-right",
                      isClosable: true,
                    });
                  } else {
                    setStep(2);
                  }
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}

        {step === 2 && (
          <Box width="100%">
            <Center width="100%" mt="10" mb="5">
              <Box width={{ sm: "100%", md: "30%" }}>
                <InputGroup size="sm">
                  <Input
                    placeholder="DBX"
                    color="#ffffff"
                    placeHolderTe
                    height="35"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                  <InputRightAddon children="DBX" height="35" />
                </InputGroup>
                <Button
                  mt="2"
                  isLoading={loading}
                  loadingText="Please Wait"
                  onClick={(e) => {
                    e.preventDefault();
                    stake();
                  }}
                >
                  Stake
                </Button>
              </Box>
            </Center>
          </Box>
        )}
      </Box>
    </>
  );
}
