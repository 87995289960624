import { Box, Grid, Flex, Button, Text, useToast } from "@chakra-ui/react";
import Match from "views/admin/match/components/Match";
import TextFilter from "components/filter/TextFilter";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { GetMatch, FindGame, ContractUpdateScore } from "services/matchService";
import { useSelector } from "react-redux";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { useParams } from "react-router-dom";

export default function MatchDetails() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { matchId } = useParams();
  const [loading, setLoading] = useState(false);
  const [match, setMatch] = useState(null);
  const [gameId, setGameId] = useState(matchId);
  const [searchText, setSearchText] = useState("");

  const getMatch = async () => {
    try {
      setLoading(true);
      const response = await GetMatch(gameId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setMatch(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const filter = { searchText };
      const response = await FindGame(filter, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setMatch(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const initUpdateScore = async (e) => {
    try {
      const walletConnect = { isConnected, walletProvider };
      if (match.settledOffchain) {
        setLoading(true);
        const winner = match.winner !== null ? match.winner : "";
        const looser = match.looser !== null ? match.looser : "";
        const response = await ContractUpdateScore(
          walletConnect,
          match._id,
          winner,
          looser,
          match.draw,
          false
        );

        if(response.success){
          toast({
            title: "Score updated on blockchain successfully",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
           setLoading(false);
           getMatch();
        }
      }
      return;
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const initUpdateUndecided = async (e) => {
    try {
      const walletConnect = { isConnected, walletProvider };
      setLoading(true);
      const response = await ContractUpdateScore(walletConnect, match._id, "", "", false, true);
      if(response.success){
        toast({
          title: "Match updated successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
         setLoading(false);
         getMatch();
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    matchId !== undefined && getMatch();
    //const initBet = socketClient(SOCKET_ENDPOINT + "/initBet");
    /*initBet.emit("initupdateroom", "room");
    initBet.on("scoreupdated", (data) => {
      toast({
        title: "Score updated on blockchain successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      getMatch();
    });*/
  }, []);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <Grid w="100%" p="10px">
        <TextFilter
          searchText={searchText}
          setSearchText={setSearchText}
          searchTextLabel="Fixture ID / Match ID"
          initFilterSubmit={initFilterSubmit}
        />
        {match !== null && <Match match={match} />}

        <Card
          mt="4"
          boxShadow="unset"
          px="24px"
          py="21px"
          transition="0.2s linear"
        >
          <Flex position="relative" align="center">
            <Text fontWeight="bold"> Actions</Text>
          </Flex>
          {match !== null &&
            match.withdrawable === false &&
            match.settledOffchain === true && (
              <Flex position="relative" align="center">
                <Button
                  me="100%"
                  mb="50px"
                  w="140px"
                  minW="140px"
                  mt={{ base: "20px", "2xl": "auto" }}
                  variant="brand"
                  fontWeight="500"
                  isLoading={loading}
                  loadingText="Wait.."
                  onClick={(e) => {
                    e.preventDefault();
                    initUpdateScore();
                  }}
                >
                  Update Score
                </Button>
              </Flex>
            )}

          { 
           match !== null && !match.settledOffchain &&
            <Flex position="relative" align="center">
            <Button
              me="100%"
              mb="50px"
              w="140px"
              minW="140px"
              mt={{ base: "20px", "2xl": "auto" }}
              variant="brand"
              fontWeight="500"
              isLoading={loading}
              loadingText="Wait.."
              onClick={(e) => {
                e.preventDefault();
                initUpdateUndecided();
              }}
            >
              Undecided
            </Button>
          </Flex>
           }
        </Card>
      </Grid>
    </Box>
  );
}
