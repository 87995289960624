import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Terms() {
  return (
    <Box minH="100vh">
      <Helmet>
        <title>AML Policy | AML Policy</title>
        <meta
          name="description"
          content="Read our AML Policy carefully before using our virtual debit card issuance service. Our AML policy outline the rules, regulations, and responsibilities that govern the use of our service. By using our service, you agree to abide by our terms and conditions."
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <Header />

      <VStack spacing="2" color="black">
        <Box>
          {" "}
          <Heading as="h3" mt="10">
            {" "}
            AML Policy
          </Heading>{" "}
        </Box>
        <Box p={{ base: "4", sm: "10", md: "20" }}>
          <OrderedList spacing={4} pl={6}>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                At swaptrex.com, we are committed to maintaining a robust
                Anti-Money Laundering (AML) policy to prevent and detect any
                illegal activities, including money laundering and terrorist
                financing.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We strictly adhere to all applicable laws and regulations
                related to AML.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                As part of our AML policy, we have implemented various measures,
                including customer due diligence, identification and
                verification procedures, ongoing monitoring of transactions, and
                reporting of suspicious activities to the relevant authorities.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We conduct thorough risk assessments and implement appropriate
                internal controls to mitigate the risks associated with money
                laundering and terrorist financing.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Our AML policy applies to all users of swaptrex.com and its
                services, and we reserve the right to request additional
                information and documentation from users to comply with AML
                requirements.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We are committed to maintaining the confidentiality and security
                of any information collected during the AML process, in
                accordance with our Privacy Policy.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Any suspected or actual instances of money laundering or
                terrorist financing will be reported to the appropriate
                authorities, and we will cooperate fully with law enforcement
                agencies in such cases.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                By using swaptrex.com and its services, you agree to comply with
                our AML policy and provide accurate and up-to-date information
                as required for AML purposes.
              </Text>
            </ListItem>
          </OrderedList>
        </Box>
      </VStack>
    </Box>
  );
}
