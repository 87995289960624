import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Heading,
  Flex,
  Tag,
  Text,
  InputGroup,
  InputRightAddon,
  Button,
  SimpleGrid,
  Input,
  VStack,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import {
  Stake,
  UpdateStake,
  ContractCreateStake,
  GetMatch,
} from "services/matchService";
//import socketClient from "socket.io-client";
const { DateTime } = require("luxon");

export default function Index() {
  const toast = useToast();
  const navigate = useNavigate();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [loading, setLoading] = useState(false);
  const [initBet, setInitBet] = useState(null);
  const [match, setMatch] = useState(null);
  const [selection, setSelection] = useState("");
  const [teamStakes, setTeamStakes] = useState(0);
  const [stake, setStake] = useState(0);
  const [odd, setOdd] = useState("win");
  const [odds, setOdds] = useState(0);
  const [withdrawable, setWithdrawable] = useState(0);
  const [dividend, setDividend] = useState(0);
  const [potentialWin, setPotentialWin] = useState(0);
  const [matchStakes, setMatchStakes] = useState(0);
  const { matchId, teamId } = useParams();

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  };

  const getMatch = async () => {
    try {
      setLoading(true);
      const response = await GetMatch(matchId);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setMatch(response.data);
        const match = response.data;
        let div;
        if (match.team1._id === teamId) {
          setSelection(match.team1.name);
          setTeamStakes(match.team1Stakes);
          div = match.drawStakes + match.team2Stakes;
          setOdd("win");
        } else if (match.team2._id === teamId) {
          setSelection(match.team2.name);
          setTeamStakes(match.team2Stakes);
          div = match.drawStakes + match.team1Stakes;
          setOdd("win");
        } else {
          if (teamId === "draw") {
            setSelection("Draw");
            setTeamStakes(match.drawStakes);
            div = match.team1Stakes + match.team2Stakes;
            setOdd("draw");
          } else {
            setTeamStakes(0);
            toast({
              title: "You have not made any outcome selection",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
          }
        }
        setMatchStakes(
          match.team1Stakes + match.drawStakes + match.team2Stakes
        );
        div = div > 0 ? div : 0.1;
        setDividend(div);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleCreateStake = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const stakeData = {
        selectedTeam: teamId==="draw"? match.team1._id: teamId,
        odds: odd,
        stake: stake,
      };
      setLoading(true);
      let response = await Stake(stakeData, matchId, token);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        //initBet.emit("initcreatestakeroom", response.data);
        const stakeId = response.data;
        const walletConnect = { isConnected, walletProvider };
        if (teamId === "draw") {
          response = await ContractCreateStake(
            walletConnect,
            response.data,
            matchId,
            stake,
            teamId,
            "draw"
          );
        } else {
          //await ContractCheckTimes(walletConnect);
          response = await ContractCreateStake(
            walletConnect,
            response.data,
            matchId,
            stake,
            teamId,
            "win"
          );
        }

        if (response.success) {
        //update the buy trade funded status
        const stakeData = { createdOnBlockchain: true, transactionHash: response.transactionHash };
        response = await UpdateStake(stakeData, stakeId, token);
          if (!response || !response?.success) {
            toast({
              title: "An error occured on the blockchain",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            setLoading(false);
            return;
          }

          toast({
            title: "Stake successful, waiting for confirmation",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setStake("");
          setLoading(false);
          navigate("/app/history");
        }
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };
  

  useEffect(() => {
    getMatch();
  }, []);

  /*
  useEffect(() => {
    getMatch();
    const initBet = socketClient(SOCKET_ENDPOINT + "/initBet");
    setInitBet(initBet);
    initBet.on("gamestaked", (data) => {
      toast({
        title: "Game has been staked successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    });
  }, []);
*/

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">
        <Box width="100%" mt="2" textAlign="center">
          <Heading color="white" size="md">
            Enter Stake Amount
          </Heading>
        </Box>

        <Center width="100%" mt="10">
          <VStack
            width={{ sm: "100%", md: "70%" }}
            p="4"
            pb="10"
            spacing="5"
            borderRadius="20"
            borderWidth={1}
            borderColor="secondary.500"
          >
            <Box width="100%">
              <Flex>
                <Heading size="sm" color="#ffffff">
                  Your Selection
                </Heading>

                <Tag ml="2" size="sm" variant="solid" colorScheme="teal">
                  {selection}
                </Tag>
              </Flex>
            </Box>

            <Box width="100%" borderTopWidth={1} borderColor="secondary.500">
              <Center width="100%" mt="10" mb="5">
                <Box width={{ sm: "100%", md: "50%" }}>
                  <InputGroup size="sm">
                    <Input
                      placeholder="DBX"
                      color="#ffffff"
                      height="35"
                      onChange={(e) => {
                        setStake(e.target.value);
                        let totalStakeByTeam =
                          teamStakes + Number(e.target.value);
                        let dividendPercentage =
                          (Number(e.target.value) * 100) / totalStakeByTeam;
                        dividendPercentage = dividendPercentage * 0.01;
                        let div = (dividendPercentage * dividend).toFixed(5);
                        // Convert dividend percentage to odds
                        let odd = div / Number(e.target.value);
                        setPotentialWin(div);
                        setOdds(odd);
                      }}
                    />
                    <InputRightAddon children="DBX" height="35" />
                  </InputGroup>
                  <Button
                    mt="2"
                    isLoading={loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCreateStake();
                    }}
                  >
                    Stake
                  </Button>
                </Box>
              </Center>
            </Box>

            <Box width="100%" borderTopWidth={1} borderColor="secondary.500">
              <SimpleGrid width="100%" mt="7" columns={2} spacing={2}>
                <Box color="#ffffff">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    <Text fontWeight="bold" as="span">
                      Total Match Stakes:
                    </Text>{" "}
                    {matchStakes} DBX
                  </Text>
                  <Text
                    fontSize={{
                      base: "xs",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    Amount staked on all outcomes
                  </Text>
                </Box>
                <Box color="#ffffff">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    <Text fontWeight="bold" as="span">
                      Total Staked on {match !== null && selection}:
                    </Text>{" "}
                    {teamStakes} DBX
                  </Text>
                  <Text
                    fontSize={{
                      base: "xs",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    Total amount staked on your selected outcome
                  </Text>
                </Box>
              </SimpleGrid>

              <SimpleGrid width="100%" columns={2} spacing={2} mt="4">
                <Box color="#ffffff">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    <Text fontWeight="bold" as="span">
                      Potential Reward:
                    </Text>{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5,
                    }).format(potentialWin)}{" "}
                    {" DBX"}
                  </Text>
                  <Text
                    fontSize={{
                      base: "xs",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    Reward excluding your stake amount which will also be
                    returned
                  </Text>
                </Box>
                <Box color="#ffffff">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    <Text fontWeight="bold" as="span">
                      Potential Odd:
                    </Text>{" "}
                    X
                    {odds > 1
                      ? new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 5,
                          maximumFractionDigits: 5,
                        }).format(odds)
                      : new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 5,
                          maximumFractionDigits: 5,
                        }).format(1 + odds)}{" "}
                    {" DBX"}
                  </Text>
                </Box>
              </SimpleGrid>
            </Box>

            <Box width="100%" textAlign="center" color="#ffffff">
              <Text>
                <Text fontWeight="bold" as="span">
                  Match
                </Text>
                <Text color="#8bffbd">
                  {match !== null && match.team1.name} Vs{" "}
                  {match !== null && match.team2.name}
                </Text>
              </Text>
              <Text mt="2">
                {match !== null && formatTime(match.matchTimestamp)}
              </Text>
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  );
}
