import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, Stack, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { MdArrowForward } from "react-icons/md";
import { createWeb3Modal, defaultConfig, useDisconnect, useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import Logo from "./Logo";

const projectId = "b160222e5051936f2cc183924976ac5d";

// 2. Set chains
const network = {
  chainId: 97,
  name: "BSC Testnet",
  currency: "BNB",
  explorerUrl: "https://explorer.binance.org/smart-testnet",
  rpcUrl: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
};

// 3. Create modal
const metadata = {
  name: "Betralix",
  description: "Betralix",
  url: "https://betralix.com",
  icons: ["https://betralix.com"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [network],
  projectId,
});

const NavBar = (props) => {

  const {handleLogin, handleLogout, loading} = props;
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { open} = useWeb3Modal();
  const { web3Modal, address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect, onDisconnect } = useDisconnect();

  const handleConnect = async () => {
      await open();  
      try {
        await open({
          networkId: 97,
        });
      } catch (error) {
        console.error("Failed to connect and switch network", error);
      }
  };

  const handleDisconnectWallet = async () => {
    await disconnect();
    handleLogout();
  };

  const handleSwitchNetwork = async (chainId) => {
    try {
      const networkParams = network;
      if (isConnected && !networkParams) {
        console.log('Network parameters not found for chainId:', chainId);
        return;
      }
      await walletProvider.request({
        method: 'wallet_addEthereumChain',
        params: [networkParams],
      });
      //await switchNetwork(chainId);
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  const ethereumAddressDisplay = (ethereumAddress) => {
    // Assuming ethereumAddress is a string with a valid Ethereum address
    const displayedAddress =
      ethereumAddress.length >= 6
        ? ethereumAddress.slice(0, 3) + ".." + ethereumAddress.slice(-3)
        : ethereumAddress;

    return displayedAddress;
  };

  useEffect(() => {
    const checkConnection = () => {
      if (isConnected) {
        const referral = localStorage.getItem("referral");
        handleLogin(address, referral);
        handleSwitchNetwork(97);
      } else {
        handleDisconnectWallet();
      }
    };    
    // Adding a timeout to ensure the state is fully updated
    const timeoutId = setTimeout(checkConnection, 700);
    return () => clearTimeout(timeoutId);
  }, [isConnected]);

   
  return (
    <NavBarContainer {...props}>
      <Logo
        w={{sm:"70px", md: "100px"}}
        color={["white", "white", "primary.500", "primary.500"]}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks
        isOpen={isOpen}
        handleConnect={handleConnect}
        isConnected={isConnected}
        address={address}
        handleDisconnectWallet={handleDisconnectWallet}
        ethereumAddressDisplay={ethereumAddressDisplay}
      />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link to={to}>
      <Text
        display="block"
        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
        fontFamily="ManropeRegular"
      >
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({
  isOpen,
  handleConnect,
  handleDisconnectWallet,
  isConnected,
  address,
  ethereumAddressDisplay,
}) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="/app/faucet">Faucet</MenuItem>
        <MenuItem to="/app/airdrop">Airdrop</MenuItem>
        {isConnected && (
          <Button
            onClick={handleDisconnectWallet}
            class="banner-btn"
            size="md"
            rounded={"full"}
            color="brand.500"
            fontFamily="ManropeLight"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            {ethereumAddressDisplay(address)}
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        )}

        {!isConnected && (
          <Button
            onClick={handleConnect}
            class="banner-btn"
            size="md"
            rounded={"full"}
            color="brand.500"
            fontFamily="ManropeLight"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            Connect
            <Icon
              as={MdArrowForward}
              width="20px"
              height="20px"
              color="white"
            />
          </Button>
        )}
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
        <Box width="100%" borderRadius="10" background="brand.500"  p="2"
        mx="2"
        mt="2">
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pt={2}
            pb={2}
            pl={0}
            pr={0}
            bg={["primary.500", "primary.500", "primary.500", "primary.500"]}
            color={["#fff", "#fff", "#fff", "#fff"]}
            {...props}
          >
            {children}
          </Flex>
        </Box>
  );
};

export default NavBar;
