import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdControlPoint,
  MdBlurOn,
  MdSportsSoccer,
  MdPageview,
} from "react-icons/md";

import MainDashboard from "views/admin/default";
import  CreateMatch from "views/admin/match/index";
import Matches from "views/admin/match/matches";
import MatchDetails from "views/admin/match/matchdetails";
import Fixtures from "views/admin/match/fixtures";
import Users from "views/admin/users";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="white" />,
    component: <MainDashboard />,
  },
  {
    name: "Add Match",
    layout: "/admin",
    path: "/addmatch",
    icon: (
      <Icon as={MdControlPoint} width="20px" height="20px" color="white" />
    ),
    component: <CreateMatch />,
  },
  {
    name: "Fixtures",
    layout: "/admin",
    path: "/fixtures",
    icon: <Icon as={MdBlurOn} width="20px" height="20px" color="white" />,
    component: <Fixtures />,
  },
  {
    name: "Matches",
    layout: "/admin",
    path: "/matches",
    icon: (
      <Icon as={MdSportsSoccer} width="20px" height="20px" color="white" />
    ),
    component: <Matches />,
  },
  {
    name: "Match Details",
    layout: "/admin",
    path: "/matchdetails/:matchId",
    icon: <Icon as={MdPageview} width="20px" height="20px" color="white" />,
    component: <MatchDetails />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="white" />,
    component: <Users />
  },
];

export default routes;
