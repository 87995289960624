import React from "react";
import {
    Box, 
    Flex
} from "@chakra-ui/react";
    import NavBar from "components/navigation/NavBar.js";
    //import TopBg from "assets/images/banner_red.png";

const Header = () => {
    return (
    <Box w="100%"  
    background="brand.500"
    backgroundPosition="center center"
    backgroundRepeat="no-repeat"
    backgroundSize="cover">
       <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      pt={2}
      pb={2}
      pl={8}
      pr={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["black", "black", "black", "black"]}
      shadow='md'>
        <NavBar />
      </Flex> 
    </Box>
    )
}

export default Header;