import React, {useState} from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  HStack,
  Flex,
  Link as ChakraLink,
  useMediaQuery,
  useToast
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import routes from "routesuser.js";
import Footer from "components/navigation/Footer.js";
import NavBar from "components/navigation/NavBarWallet.js";
import { useSelector, useDispatch } from "react-redux";
import { UserLogin } from "services/userService";
import { setUser } from "utils/userSlice";

const UserLayout = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isLargerThanMedium] = useMediaQuery("(min-width: 48em)");
  const [isSmallerThanMedium] = useMediaQuery("(max-width: 47.99em)");

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;

  const handleLogin = async (address, referral) => {
    if(isLoggedIn) return false;
    try {
      setLoading(true);
      const response = await UserLogin(address, referral);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        dispatch(setUser(response.data));
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleLogout = async () => {
    dispatch(setUser({}));
  };

  return (
    <Box>
      {/* Sidebar or Footerbar based on screen size */}
      {isLargerThanMedium ? (
        <Box
          as="aside"
          w="100px"
          h="100vh"
          bg="#001247"
          color="white"
          zIndex="10"
          position="fixed"
          top="0"
          left="0"
          paddingTop="20"
        >
          {routes.map((route) => (
            <ChakraLink
              as={ReactRouterLink}
              to={route.layout + "/" + route.path}
              mt="25"
            >
              <NavItem key={route.name} icon={route.icon}>
                {route.name}
              </NavItem>
            </ChakraLink>
          ))}
        </Box>
      ) : (
        isSmallerThanMedium && (
          <Box
            as="footer"
            w="100%"
            h="80px"
            bg="#001247"
            color="white"
            position="fixed"
            bottom="0"
            left="0"
            paddingTop="0"
            zIndex={100000}
          >
            <Flex w="100%" justifyContent="space-between">
            {routes.map((route) => (
              <ChakraLink
                as={ReactRouterLink}
                to={route.layout + "/" + route.path}
              >
                <NavItem key={route.name} icon={route.icon}>
                  {route.name}
                </NavItem>
              </ChakraLink>
            ))}
            </Flex>
          </Box>
        )
      )}

      {/* Main content */}
      <Box ml={isLargerThanMedium ? "100px" : "0"} minH="100vh" bg="#070b24">
        <HStack background="none">
          <NavBar zIndex="10" handleLogin={handleLogin} handleLogout={handleLogout} loading={loading} />
        </HStack>
        <Box p="4" width="100%">
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box
      p="4"
      pb="6"
      mx="4"
      mt="15"
      mb="15"
      width="50"
      height="55"
      fontSize="20"
      color="white"
      align="center"
      className="sidebar-icon"
      _hover={{
        bg: "cyan.400",
        color: "white",
      }}
    >
    {icon}
    </Box>
  );
};

export default UserLayout;
