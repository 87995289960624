import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  Box,
  Skeleton,
  SkeletonText,
  Grid,
  useToast,
} from "@chakra-ui/react";
import MatchesTable from "views/admin/match/components/MatchesTable";
import DateFilter from "components/filter/DateFilter";
import { GetGames } from "services/matchService";
import { useSelector } from "react-redux";

const { DateTime } = require("luxon");

export default function Matches() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [date, setDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"));
  const [columnsData, setColumnsData] = useState([
    {
      Header: "FIXTURE ID",
      accessor: "fixtureid",
    },
    {
      Header: "HOME",
      accessor: "home",
    },
    {
      Header: "AWAY",
      accessor: "away",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "ACTION",
      accessor: "action",
    },
  ]);
  const toast = useToast();

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromSeconds(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_FULL);
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const filter = { date };
      const response = await GetGames(filter, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        const allmatches = [];
        response.data.map((match) => {
          allmatches.push({
            fixtureid: match.fixtureId,
            home: match.team1.name,
            away: match.team2.name,
            homelogo: match.team1.logo,
            awaylogo: match.team2.logo,
            date: formatTime(match.matchTimestamp),
            action: match._id,
          });
        });
        setTableData(allmatches);
        setTotalRecord(allmatches.length);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    initFilterSubmit();
  }, []);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <Grid w="100%" p="10px">
        <DateFilter
          loading={loading}
          date={date}
          setDate={setDate}
          initFilterSubmit={initFilterSubmit}
        />

        {loading ? (
          <LoadingMatches />
        ) : (
          tableData.length > 0 && (
            <MatchesTable
              columnsData={columnsData}
              tableData={tableData}
              totalRecord={totalRecord}
            />
          )
        )}

      </Grid>
    </Box>
  );
}

const LoadingMatches = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
