import React from "react";
import {
  Container,
  SimpleGrid,
  Center,
  Circle,
  Image,
  Heading,
  Text,
  Box,
  Stack,
  VStack,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { MdArrowForward } from "react-icons/md";

import Bg1 from "assets/images/bg3transparent.png";

import Blockchain from "assets/images/blockchain.png";
import Peer from "assets/images/peer.png";
import Limitless from "assets/images/limitless.png";
import Protection from "assets/images/protection.png";

const Feature = ({ text, icon }) => {
  return (
    <Stack direction={"row"} align={"start"} pt="0" pb="0" mt="0" mb="0">
      <Circle w={10} h={10} bg="brand.500">
        {icon}
      </Circle>
      <Text
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
        fontWeight="ManropeLight"
        color="black"
      >
        {text}
      </Text>
    </Stack>
  );
};

const WhatMakesUs = () => {
  return (
    <Container
      maxW={"100%"}
      py={12}
      background="#070b24"
      backgroundSize="100% auto"
      backgroundRepeat="no-repeat"
    >
      <Container maxW={"5xl"}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt="20">
          <Box>
            <Center width="100%" height="100%">
              <VStack>
                <Box width="100%">
                  <Heading
                    textAlign="start"
                    as="h3"
                    fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
                    fontFamily="Spantran"
                    fontWeight="400"
                    color="white"
                  >
                    About &nbsp;
                    <Text as="span" color="secondary.500">
                      Betralix
                    </Text>
                  </Heading>
                </Box>
                <Box width="100%">
                  <Text
                    textAlign="justify"
                    fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                    fontFamily="ManropeLight"
                    color="white"
                  >
                    Betralix is a unique peer-2-peer sports betting platform.
                    Unlike traditional sports betting platforms, there is no
                    middleman involved, Betralix platform relies on blockchain
                    and is entirely powered by smart contract. The smart
                    contract is programmed to automatically execute certain
                    functions and these include accepting bets from players,
                    calculation of winnings, and paying out winnings to wallets.
                    A smart contract, once deployed, is immutable which means
                    that the code cannot be changed. Betralix is very
                    user-friendly as you don't have all the complex options to
                    deal with, it doesn’t require identity verification for
                    deposits and withdrawals.
                  </Text>
                </Box>
              </VStack>
            </Center>
          </Box>
          <Box>
            <SimpleGrid columns="2" spacing="5">
              <Box>
                <VStack spacing="5">
                  <Box
                    boxShadow="lg"
                    background="brand.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Center>
                            <Image
                              src={Blockchain}
                              alt="blockchain"
                              width="70"
                              height="70"
                            />
                          </Center>
                          <Heading
                            mt="2"
                            color="white"
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "md",
                              lg: "lg",
                            }}
                            fontFamily="Spantran"
                            fontWeight="300"
                          >
                            Decentralized
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                  <Box
                    boxShadow="lg"
                    background="secondary.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box
                          textAlign="center"
                          fontWeight="bold"
                          color={"brand.500"}
                          fontSize={{
                            base: "sm",
                            sm: "sm",
                            md: "md",
                            lg: "lg",
                          }}
                          fontFamily="Spantran"
                        >
                          <Center>
                            <Image
                              src={Limitless}
                              alt="blockchain"
                              width="70"
                              height="70"
                            />
                          </Center>
                          <Heading
                            mt="2"
                            color={"brand.500"}
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "md",
                              lg: "lg",
                            }}
                            fontFamily="Spantran"
                            fontWeight="300"
                          >
                            No Limit
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                </VStack>
              </Box>
              <Box>
                <VStack spacing="5" mt="20">
                  <Box
                    boxShadow="lg"
                    background="brand.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Center>
                            <Image
                              src={Peer}
                              alt="blockchain"
                              width="70"
                              height="70"
                            />
                          </Center>
                          <Heading
                            mt="2"
                            textAlign="center"
                            color="white"
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "md",
                              lg: "lg",
                            }}
                            fontFamily="Spantran"
                            fontWeight="300"
                          >
                            Peer-to-Peer
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                  <Box
                    boxShadow="lg"
                    background="secondary.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Center>
                            <Image
                              src={Protection}
                              alt="blockchain"
                              width="70"
                              height="70"
                            />
                          </Center>
                          <Heading
                            mt="2"
                            textAlign="center"
                            color={"brand.500"}
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "md",
                              lg: "lg",
                            }}
                            fontFamily="Spantran"
                            fontWeight="200"
                          >
                            Simple & Safe
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default function About() {
  return (
    <>
      <Container
        maxW={"100%"}
        py={12}
        background="brand.500"
        backgroundImage={`url(${Bg1})`}
        backgroundSize="100% auto"
        backgroundRepeat="no-repeat"
      >
        <Container maxW={"5xl"} py={12} background="transparent">
          {/*<Box width="100%" textAlign="center">
       <Heading
          as="h3"
          fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
          fontFamily="Spantran"
          color="white"
        >Dexplays</Heading>
       </Box>*/}

          <Box width="100%" textAlign="center" mt="6">
            <Heading
              fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
              fontFamily="Spantran"
              color="white"
            >
              Decentralized Sport Betting With <Text>Proof of Stake</Text>
            </Heading>
          </Box>

          <Box width="100%" textAlign="center" mt="6">
            <Link to="/app/matches">
              <Button
                class="banner-btn"
                size="md"
                rounded={"full"}
                color="brand.500"
                fontFamily="ManropeLight"
                _hover={{
                  bg: [
                    "primary.100",
                    "primary.100",
                    "primary.600",
                    "primary.600",
                  ],
                }}
              >
                Launch App &nbsp;{" "}
                <Icon
                  as={MdArrowForward}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Button>
            </Link>
            &nbsp;&nbsp;
            <a href={"https://everlink.tools/betralix"} target="_blank">
              <Button
                class="secondary-btn"
                size="md"
                rounded={"full"}
                color="secondary.500"
                fontFamily="ManropeLight"
                _hover={{
                  bg: [
                    "primary.100",
                    "primary.100",
                    "primary.600",
                    "primary.600",
                  ],
                }}
              >
                Join Community &nbsp;{" "}
                <Icon
                  as={MdArrowForward}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Button>
            </a>
          </Box>
        </Container>
      </Container>
      <WhatMakesUs />
    </>
  );
}
