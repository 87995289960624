import axios from "axios";
import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import { ethers, BrowserProvider } from "ethers";
import token from "blockchain/IBEP20.json";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const TOKEN_ADDRESS = "0xA872679b5D96E30D924A0AB0952F54D72b26EEf5";

const UserLogin = async (address, referral) => {
  try {
    const response = await axios.post(
      SERVER_URL + "/auth/login",
      { address, referral },
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUser = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/user/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetReferrals = async (pagination, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL +
        "/user/referrals?from=" +
        pagination.from +
        "&limit=" +
        pagination.limit +
        "&orderBy=" +
        pagination.orderBy +
        "&sortOrder=" +
        pagination.sortOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateUser = async (userData, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/user", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const VerifyEmail = async (userData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/user/verifyEmail", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ResendEmailVerification = async (token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/user/resendEmailVerification", {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetTokenBalance = async (walletConnect, address) => {
  try {
    if (!walletConnect || !walletConnect.isConnected)
    throw new Error("Wallet not connected");
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const TokenContract = new ethers.Contract(
      TOKEN_ADDRESS,
      token.abi,
      signer
    );

    const TokenBalance = await TokenContract.balanceOf(address);
    const balance = ethers.formatUnits(TokenBalance, 18);
    
    return balance;
  } catch (error) {
    return 0;
  }
};

const DeboxAccessToken = async (code, userId) => {
  try {
    const response = await axios.get('https://open.debox.pro/openapi/oauth2/access_token', {
      params: {
        grant_type: 'authorization_code',
        code,
        app_id: 'urtjHOpJqxVVDM13', // Replace with your app ID
        app_secret: 'VHTv9xBIzJIvuwKqBVk6zZhEktToAXP7', // Replace with your app secret
        user_id: userId, // Replace with user ID if needed
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching access token', error);
  }
};


const ShareDeboxMessage = async (title, content) => {
  const url = "https://open.debox.pro/openapi/messages/group/send";
  const apiKey = "4VxNfgRucjj754Q0"; // Replace with your actual API key

  const data = {
    group_id: "eqcyvvr1",
    object_name: "text",
    title: title,
    content: content,
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": apiKey,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

//https://m.debox.pro/group?id=cc0qdpyu&code=RR0LYcNu

export { UserLogin, ResendEmailVerification, VerifyEmail, GetUser, GetReferrals, UpdateUser, GetTokenBalance, DeboxAccessToken, ShareDeboxMessage };
