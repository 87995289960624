import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  Button,
  SimpleGrid,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { UpdateUser, ResendEmailVerification, GetUser, VerifyEmail, GetTokenBalance } from "services/userService";
import { GetTodayPoints } from "services/statService";

export default function Index() {
  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [user, setUser] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [todayPoints, setTodayPoints] = useState(0);
  const [balance, setBalance] = useState(0);

  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(true);

  const { code } = useParams();

  const handleUpdateProfile = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (emailAddress === "") {
        toast({
          title: "Email address cannot be empty",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const userData = { emailAddress };
      const response = await UpdateUser(userData, token);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        toast({
          title: response.message || "Your email have been updated successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowResendLink(false);
        setRemainingTime(90);
        getUser();
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleResendEmailVerification = async () => {
    try {
      setLoading(true);
      const response = await ResendEmailVerification(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowResendLink(false);
        setRemainingTime(90);
        getUser();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const response = await GetUser(token);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setUser(response.data);
        setEmailAddress(response.data.emailAddress);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getTodayPoints = async () => {
    try {
      const response = await GetTodayPoints(token);
      if (response.success === true) {
        setTodayPoints(response.data?.todayPoints);
      }
    } catch (error) {}
  };

  const getTokenBalance = async () => {
    try {
      const walletConnect = { isConnected, walletProvider };
      const tokenBalance = await GetTokenBalance(walletConnect, address);
      setBalance(tokenBalance);
    } catch (error) {}
  };

  const handleVerifyEmail = async () => {
    try {
      setLoading(true);
      const response = await VerifyEmail({ longOtpCode: code }, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (token !== null) {
       getUser();
       getTodayPoints();
     } 
    if(token !== null && code!==null && code!==undefined){
      handleVerifyEmail();
    }
  }, [token]);

  useEffect(() => {
    if (address) {
      getTokenBalance();
    }
  }, [address, chainId]);

   useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" pb="20px">

       <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "lg", lg: "2xl" }}>
          Profile
          </Heading>
        </Box>


        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
          spacing={2}
          mb="4"
        >
          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Total Points
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {user !== null
                  ? new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(user.totalPoint)
                  : "0 Pts"}
              </Text>
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                Today's Point
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(todayPoints)}{" "}
                Pts
              </Text>
            </Box>
          </Box>

          <Box minHeight="70" className="trade-record-card">
            <Box minHeight="70" width="100%" pt="3">
              <Text color="white" textAlign="center">
                DBX Balance
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center">
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(balance)}{" "}
                DBX
              </Text>
            </Box>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, sm: 1, md: 4, lg: 4 }}
          spacing={2}
          className="trade-record-card"
          p="5"
        >
          <Box textAlign={{ sm: "left", md: "center" }} height="30">
              <Text color="white" fontWeight="bold">
                Email
              </Text>
          </Box>
          <Box textAlign={{ sm: "left", md: "center" }}>
              <Text color="white">
                Your email needs to be valid to recieve update
              </Text>
          </Box>
          <Box>
            <Input
              placeholder="Email"
              defaultValue={user !== null ? user.emailAddress : ""}
              readOnly={user !== null && user.emailVerified}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
            />
            {user !== null && !user.emailVerified && showResendLink && (
              <Button
                mt="2"
                colorScheme="orange"
                size="xs"
                onClick={(e) => {
                  e.preventDefault();
                  handleResendEmailVerification();
                }}
                isLoading={loading}
              >
                Verify Now
              </Button>
            )}
            {
              remainingTime>0 && <Text>&nbsp;{remainingTime}s </Text>
            }
          </Box>
          <Box height="30" textAlign={{ sm: "right", md: "center" }}>
            <Button
              isLoading={loading}
              disabled={user !== null && user.emailVerified}
              loadingText="Wait..."
              onClick={handleUpdateProfile}
              color="#ffffff"
              _hover={{color: "#000000", background: "#ffffff"}}
              variant={"outline"}
              size="sm"
            >
              Update
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
}
