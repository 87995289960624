import axiosInstance from './axiosInstance'; // Adjust the path accordingly
import { ethers, BrowserProvider } from "ethers";
import betting from "blockchain/Betting.json";
import token from "blockchain/IBEP20.json";

const BETTING_CONTRACT_ADDRESS = "0x91351c52bCe5F3C5908B295CB6f953fe18BC32A5";
const TOKEN_ADDRESS = "0xA872679b5D96E30D924A0AB0952F54D72b26EEf5";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetMatchHistory = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
        "/stake/me?from=" +
        pagination.from +
        "&limit=" +
        pagination.limit +
        "&orderBy=" +
        pagination.orderBy +
        "&sortOrder=" +
        pagination.sortOrder,
      { filter },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ContractCreateStake = async (
  walletConnect,
  stakeId,
  matchId,
  stake,
  teamId,
  odd
) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    const Token = new ethers.Contract(TOKEN_ADDRESS, token.abi, signer);
    const wei = ethers.parseEther(String(stake));
    let transaction = await Token.approve(BETTING_CONTRACT_ADDRESS, wei);
    await transaction.wait();

    // Set a timeout in case the transaction takes too long
    const TIMEOUT_MS = 180000; // 1 minute
    let timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    let receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (!receipt && receipt.status !== 1) {
      throw new Error("Transaction failed");
    } 

    transaction = await BetContract.createStake(
      stakeId,
      matchId,
      wei,
      teamId,
      odd
    );

    // Set a timeout in case the transaction takes too long
    timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
    );
    receipt = await Promise.race([transaction.wait(), timeoutPromise]);
    if (receipt && receipt.status === 1) {
      // Transaction was successful
      const transactionHash = transaction.hash;
      return {
        success: true,
        transactionHash
      };
    } else {
      throw new Error("Transaction failed");
    }
 
  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
 }
};

const ContractAddStake = async (walletConnect, stakeId, amount) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
  
    const Token = new ethers.Contract(TOKEN_ADDRESS, token.abi, signer);
    let wei = ethers.parseEther(String(amount));
    let transaction = await Token.approve(BETTING_CONTRACT_ADDRESS, wei);
     // Set a timeout in case the transaction takes too long
     const TIMEOUT_MS = 180000; // 1 minute
     let timeoutPromise = new Promise((_, reject) =>
       setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
     );
     let receipt = await Promise.race([transaction.wait(), timeoutPromise]);
     if (!receipt && receipt.status !== 1) {
       throw new Error("Transaction failed");
     } 
     
    transaction = await BetContract.addStake(stakeId, wei);
     // Set a timeout in case the transaction takes too long
     timeoutPromise = new Promise((_, reject) =>
       setTimeout(() => reject(new Error("Transaction timed out")), TIMEOUT_MS)
     );
     receipt = await Promise.race([transaction.wait(), timeoutPromise]);
     if (receipt && receipt.status === 1) {
       // Transaction was successful
       const transactionHash = transaction.hash;
       return {
         success: true,
         transactionHash
       };
     } else {
       throw new Error("Transaction failed");
     }

  } catch (error) {
    let errorMessage = "An unexpected error occurred";
    if (error.hasOwnProperty("code")) {
      switch (error.code) {
        case 4001:
          errorMessage = "Transaction rejected by user";
          break;
        case "ACTION_REJECTED":
          errorMessage = "Transaction rejected by user";
          break;
        default:
          errorMessage = error.message || "An unexpected error occurred";
      }
    } else if (error.hasOwnProperty("error")) {
      if (error.error.hasOwnProperty("data") && error.error.data.hasOwnProperty("message")) {
        errorMessage = error.error.data.message;
      } else if (error.error.hasOwnProperty("message")) {
        errorMessage = error.error.message;
      }
    } else if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    throw new Error(`Transaction failed with an unknown error, contact support`);
  }
};

const ContractRemoveStake = async (walletConnect, stakeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    let transaction = await BetContract.removeStake(stakeId);
    let receipt = await transaction.wait();
    /*BetContract.once("StakeRemoved", () => {
      alert("Stake Removed");
    });*/
    return { success: true, transactionHash: receipt.transactionHash };
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const ContractWithrawReward = async (walletConnect, stakeId) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    let transaction = await BetContract.withrawFund(stakeId);
    let receipt = await transaction.wait();
    return { success: true, transactionHash: receipt.transactionHash };
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const ContractCreateMatch = async (
  walletConnect,
  gameId,
  team1,
  team2,
  matchTimeStamp
) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    const teamADefaultStake = ethers.parseEther(String(10));
    const teamBDefaultStake = ethers.parseEther(String(10));

    const transaction = await BetContract.createGame(
      gameId,
      team1,
      team2,
      teamADefaultStake,
      teamBDefaultStake,
      matchTimeStamp
    );
    let receipt = await transaction.wait();
    return { success: true, transactionHash: receipt.transactionHash };
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const ContractUpdateScore = async (
  walletConnect,
  gameId,
  winner,
  looser,
  draw,
  undecided
) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    const transaction = await BetContract.updateScore(
      gameId,
      winner,
      looser,
      draw,
      undecided
    );
    let receipt = await transaction.wait();
    return { success: true, transactionHash: receipt.transactionHash };
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const ContractCheckTimes = async (
  walletConnect
) => {
  if (!walletConnect || !walletConnect.isConnected) throw new Error("Wallet not connected");
  try {
    const ethersProvider = new BrowserProvider(walletConnect.walletProvider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    const BetContract = new ethers.Contract(
      BETTING_CONTRACT_ADDRESS,
      betting.abi,
      signer
    );
    const transaction = await BetContract.checkBlockTime();
    const checkBlockTime = transaction.toString();
    console.log(checkBlockTime);
    //const tx1 = await transaction.wait();

    const transaction2 = await BetContract.checkMatchTime('65a4c22387620a775bded89b');
    const checkMatchTime = transaction2.toString();
    console.log(checkMatchTime);
    //const tx2 = await transaction2.wait();
    //console.log(transaction2);
  
    return true;
  } catch (error) {
    if (
      error.hasOwnProperty("data") &&
      error.hasOwnProperty("data") &&
      error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.data.message);
    } 
    else if (
      error.hasOwnProperty("error") &&
      error.error.hasOwnProperty("data") &&
      error.error.data.hasOwnProperty("message")
    ) {
      throw new Error(error.error.data.message);
    } 
    else {
      throw new Error(error.message);
    }
  }
};

const Stake = async (stake, matchId, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/stake/" + matchId, stake, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateStake = async (stakeData, stakeId, token) => {
  try {
    const response = await axiosInstance.patch(SERVER_URL + "/stake/" + stakeId, stakeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetMatches = async (filter, pagination) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
        "/game/all?from=" +
        pagination.from +
        "&limit=" +
        pagination.limit +
        "&orderBy=" +
        pagination.orderBy +
        "&sortOrder=" +
        pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetGames = async (filter, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/game/matches", filter, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetMatch = async (matchId) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/game/" + matchId, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetFixture = async (fixtureId, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/game/fixture",
      {
        fixtureId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetFixtures = async (date, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/game/fixtures",
      {
        date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetStake = async (stakeId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/stake/" + stakeId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetLeagues = async () => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/league/all", {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchMatches = async (searchFilter) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/game/search",
      searchFilter,
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const FindGame = async (filter, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/game/searchbyid", filter, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  GetMatchHistory,
  GetLeagues,
  GetMatches,
  GetGames,
  GetMatch,
  FindGame,
  GetFixture,
  GetFixtures,
  GetStake,
  SearchMatches,
  Stake,
  UpdateStake,
  ContractCreateStake,
  ContractAddStake,
  ContractRemoveStake,
  ContractWithrawReward,
  ContractCreateMatch,
  ContractUpdateScore,
  ContractCheckTimes,
};




